const ChevronDownIcon = ({ size, open }) => {
  return (
    <svg
      className={`fill-current transition duration-300 ${size} mx-2 self-center ${open ? "" : "transform rotate-180"}`}
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.564 0H0.436042C0.0722788 0 -0.130838 0.392476 0.094437 0.660416L6.15839 7.84575C6.33197 8.05142 6.66619 8.05142 6.84161 7.84575L12.9056 0.660416C13.1308 0.392476 12.9277 0 12.564 0Z" />
    </svg>

  );
};

export default ChevronDownIcon;
