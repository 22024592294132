import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";

import FacebookIcon from "../../assets/Icons/FacebookIcon";
import InstagramIcon from "../../assets/Icons/InstagramIcon";
import TwitterIcon from "../../assets/Icons/TwitterIcon";
// import OauthHelper from "../../../../backend/utils/OAuthHelper";

export default function AddAccountModal({ isOpen, setIsOpen }) {

    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState([]);
    const [instapos, setInstaPos] = useState("");
    const [twitter, setTwitter] = useState("");

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    const fetchProfileDetails = () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            window.location.href = "/login";
        } else {
            const decoded = jwt.verify(
                token,
                "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            //axios to fetch information
            var profFetchConfig = {
                method: "get",
                url: `https://aware.y-f.info/api/user/profile/${ decoded.id }`,
            };
            axios(profFetchConfig)
                .then((response) => {
                    setFacebook(response.data.facebookStatus);
                    setInstagram(response.data.instagramStatus);
                    setInstaPos(response.data.instagramAccountPos);
                    setTwitter(response.data.twitterStatus);
                })
                .catch((err) => { });
        }
    };

    const _twitterOauth = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            window.location.href = "/login";
        } else {
            try {
                const decoded = jwt.verify(
                    token,
                    "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
                );
                // console.log('Decoded: ', decoded);

                window.open(
                    `https://aware.y-f.info/twitter/setting/${ decoded.id }`,
                    "_self"
                );

                // console.log('currentUser', currentUser);
            } catch (err) {
                console.log(err);
                localStorage.removeItem("authToken");
                window.location.href = "/";
            }
        }

        //open twitter auth page on current page
    };

    const _facebookOauth = async () => {
        console.log("clicked");
        const token = localStorage.getItem("authToken");
        if (!token) {
            window.location.href = "/login";
        } else {
            try {
                const decoded = jwt.verify(
                    token,
                    "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
                );
                // console.log('Decoded: ', decoded);

                window.open(
                    `https://aware.y-f.info/facebook/setting/${ decoded.id }`,
                    "_self"
                );

                // console.log('currentUser', currentUser);
            } catch (err) {
                console.log(err);
                localStorage.removeItem("authToken");
                window.location.href = "/";
            }
        }

        //open twitter auth page on current page
    };

    // instagram oauth
    const _instaOauth = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            window.location.href = "/login";
        } else {
            try {
                const decoded = jwt.verify(
                    token,
                    "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
                );

                // console.log('Decoded: ', decoded);
                const clearLogout = async () => {
                    let loggingOut = window.open(
                        "http://instagram.com/accounts/logout/",
                        "width=0, height=0"
                    );
                    console.log("open");
                    return loggingOut;
                };

                // const closing = async () => {
                clearLogout().then((value) => {
                    // console.log(value);
                    // value.close();
                    setTimeout(() => {
                        value.close();
                    }, 1000);

                    setTimeout(() => {
                        window.open(
                            `https://aware.y-f.info/instagram/authorize/${ decoded.id }`,
                            "_self"
                        );
                    }, 1500);
                });
                // };

                // console.log('currentUser', currentUser);
            } catch (err) {
                console.log(err);
                localStorage.removeItem("authToken");
                window.location.href = "/";
            }
        }
    };

    const _instaAccountSwitch = (pos) => {
        return () => {
            console.log(instapos);
            const token = localStorage.getItem("authToken");
            if (!token) {
                window.location.href = "/login";
            } else {
                try {
                    const decoded = jwt.verify(
                        token,
                        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
                    );
                    // console.log('Decoded: ', decoded);

                    var instaSwitchConfig = {
                        method: "get",
                        url: `https://aware.y-f.info/instagram/swtich/user/${ decoded.id }/${ pos }`,
                    };
                    axios(instaSwitchConfig)
                        .then((response) => {
                            setInstaPos(response.data.position);
                        })
                        .catch(() => {
                            alert("error in switching account");
                        });

                    // console.log('currentUser', currentUser);
                } catch (err) {
                    console.log(err);
                    localStorage.removeItem("authToken");
                    window.location.href = "/";
                }
            }
        };
    };

    const removeLoggedIn = (value) => {
        return () => {
            const token = localStorage.getItem("authToken");
            if (!token) {
                window.location.href = "/login";
            } else {
                try {
                    const decoded = jwt.verify(
                        token,
                        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
                    );
                    var config = {
                        method: "get",
                        url: `https://aware.y-f.info/api/user/remove/social/${ decoded.id }/${ value }`,
                    };
                    axios(config)
                        .then((response) => {
                            fetchProfileDetails();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (err) {
                    console.log(err);
                    localStorage.removeItem("authToken");
                    window.location.href = "/";
                }
            }
        };
    };


    function handleClose() {
        setIsOpen(false);
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    static
                    as="div"
                    open={false}
                    className="fixed inset-0 right-80 overflow-y-auto"
                    onClose={async () => {
                        setIsOpen(false);
                    }}
                >
                    <div className="min-h-full px-4 ml-40 overflow-hidden 2xl:ml-0 2xl:text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed bg-black opacity-60 inset-0 top-20" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <button onClick={handleClose}><img src="/imgs/cross.svg" alt="close" /></button>
                                <h2 className="mb-5">Connect Social Media Accounts</h2>
                                <div className="mx-10 flex flex-row gap-5">

                                    {facebook ? (
                                        <div className="bg-gray-100 rounded-lg min-w-max px-4 py-8 flex flex-col self-center gap-y-4">
                                            <FacebookIcon fill={"#4D669B"} className="justify-center" />
                                            <div className="flex flex-row gap-x-1 self-center">
                                                <h4 className="self-center">Facebook</h4>
                                                <img width={20} src="/imgs/tick.svg" alt="tick" />
                                            </div>
                                            <button className="px-4 py-2 bg-gray-800 rounded-md text-white" onClick={removeLoggedIn("facebook")}>
                                                Disconnect
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 rounded-lg min-w-max px-4 py-8 flex flex-col self-center gap-y-4">
                                            <FacebookIcon fill={"#4D669B"} className="justify-center" />
                                            <h4 className="self-center">Facebook</h4>
                                            <button
                                                className="bg-ss-blue text-white rounded-md min-w-max px-4 py-2"
                                                onClick={_facebookOauth}
                                            >
                                                Connect
                                            </button>
                                        </div>
                                    )}

                                    <div className="flex flex-col gap-y-2">
                                        {instagram.length > 0 ? (
                                            <div className="bg-gray-100 rounded-lg min-w-max px-4 py-8 flex flex-col self-center gap-y-4">
                                                <InstagramIcon fill={"#FF3C5F"} className="justify-center" />
                                                <div className="flex flex-row gap-x-1 self-center">
                                                    <h4 className="self-center">Instagram</h4>
                                                    <img width={20} src="/imgs/tick.svg" alt="tick" />
                                                </div>
                                                <button className="px-4 py-2 bg-gray-800 rounded-md text-white" onClick={removeLoggedIn("instagram" + 0)}>
                                                    Disconnect
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="bg-gray-100 rounded-lg min-w-max px-4 py-8 flex flex-col self-center gap-y-4">
                                                <InstagramIcon fill={"#FF3C5F"} className="justify-center" />
                                                <h4 className="self-center">Instagram</h4>
                                                <button
                                                    className="bg-ss-blue text-white rounded-md min-w-max px-4 py-2"
                                                    onClick={_instaOauth}
                                                >
                                                    Connect
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {twitter ? (

                                        <div className="bg-gray-100 rounded-lg min-w-max px-4 py-8 flex flex-col self-center gap-y-4">
                                            <TwitterIcon fill={"#109CF1"} className="justify-center" />
                                            <div className="flex flex-row gap-x-1 self-center">
                                                <h4 className="self-center">Twitter</h4>
                                                <img width={20} src="/imgs/tick.svg" alt="tick" />
                                            </div>
                                            <button className="px-4 py-2 bg-gray-800 rounded-md text-white" onClick={removeLoggedIn("twitter")}>
                                                Disconnect
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 rounded-lg min-w-max px-4 py-8 flex flex-col self-center gap-y-4">
                                            <TwitterIcon fill={"#109CF1"}  className="justify-center" />
                                            <h4 className="self-center">Twitter</h4>
                                            <button
                                                className="bg-ss-blue text-white rounded-md min-w-max px-4 py-2"
                                                onClick={_twitterOauth}
                                            >
                                                Connect
                                            </button>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
