import { Popover, Transition } from "@headlessui/react";
import DeleteIcon from "../assets/Icons/DeleteIcon";
import TripleDots from "../assets/Icons/TripleDots";
import EditIcon from "../assets/Icons/EditIcon";
import HighlightNew from "../common/HighlightNew";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useState, useEffect } from "react";

function ViewDraft({
  index,
  textToHighlight,
  analyse,
  setAnalyse,
  setCurrentIndex,
  keywords,
  setKeywords,
  posts,
  setPosts,
  allPosts,
  active,
  currentIndex,
  highlightColor,
  setHighlightColor,
  imageUrl,
  date,
  username,
  postId,
  setDraftsAvailable,
  seteditdraftId,
  setdraftEdit,
  openModal,
  isMobile,
  setPostMobile,
  getDraftPosts,
  setNewPost,
  setAnalyseLoad,
  analyseLoad,
  selectAll,
  showText,
  setText,
  allIssues,
  setAllIssues,
}) {
  const [activeButton, setActiveButton] = useState(false);

  function toggleAnalyse() {
    setAllIssues([]);
    if (activeButton) {
      setActiveButton(false);
      setAnalyse(false);
    } else {
      if (analyse) {
        setAnalyse(false);
        setActiveButton(true);
        setAnalyse(true);
      } else {
        setAnalyse(true);
        setActiveButton(true);
      }
    }
  }

  function checkNotNull(x) {
    if (!x) {
      setDraftsAvailable(false);
      return false;
    }
    setDraftsAvailable(true);
    return true;
  }

  const startAnalyse = async () => {
    // await setAnalyse(!analyse);
    // // clear the keywords after each analyse toggle to false

    // // set index of current post after toggling analyse
    // setCurrentIndex(index);
    var issues = [];
    document.body.style.cursor = 'wait';
    if(activeButton === false){
      setAnalyseLoad(true);
    }

    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      let data = JSON.stringify({
        desc: posts[index][0],
      });
      var startAnalyseconfig = {
        method: "post",
        url: `https://aware.y-f.info/twitter/check/and/tell/${decoded.id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(startAnalyseconfig)
        .then(async (resp) => {
          const userPost = [
            resp.data[0],
            resp.data[1],
            resp.data[2],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            resp.data[3],
            resp.data[4],
          ];
          posts[index][1] = resp.data[1];
          posts[index][2] = resp.data[2];
          posts[index][11] = resp.data[3];
          posts[index][12] = resp.data[4];

          await setPosts([...allPosts]);
          await setCurrentIndex(index);
          toggleAnalyse();
          // console.log(setAnalyse();
          await setKeywords(
            [
              ...resp.data[1],
              ...resp.data[2],
              ...resp.data[3],
              ...resp.data[4],
            ].map((word) => RegExp(`\\b${word}\\b`))
          );
          [
            ...resp.data[1],
            ...resp.data[2],
            ...resp.data[3],
            ...resp.data[4],
          ].map((word) => {
            if (word.dataNeeded) {
              issues.push({ key: word.dataNeeded.key, type: "URL" });
            } else if (word.sentiment) {
              // Pushing term to the right side bar
              if (word.sentiment.por < 0) {
                issues.push({
                  key: word.sentiment.sTerm.join(" "),
                  type: "NEGPOL",
                });
                // issues.push({key:word.sentiment.sTerm, type:"NEGPOL"});
              } else if (word.sentiment.por > 0) {
                issues.push({
                  key: word.sentiment.sTerm.join(" "),
                  type: "POSPOL",
                });
              }
            } else {
              issues.push({ key: word[0], type: word[1] });
            }
          });
          issues = issues.filter(function (element) {
            return element.key !== undefined;
          });
          setText([]);
          setAllIssues(issues);
          document.body.style.cursor = 'default';
          await setAnalyseLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (checkNotNull(posts[currentIndex])) {
      if (analyse) {
        setKeywords(
          posts[currentIndex][2].map((word) => RegExp(`\\b${word}\\b`))
        );
      } else {
        setKeywords([new RegExp("")]);
        setHighlightColor("rgba(249, 166, 17, 0.5)");
      }
    } else {
      console.log("No Drafts Available!");
    }
  }, [analyse, currentIndex, posts, setKeywords, setHighlightColor]);

  useEffect(() => {
    if (analyse && active === false) {
      setActiveButton(false);
    }
  }, [analyse, active, setActiveButton]);

  /// delete draft post
  const deleteDraft = async () => {
    const getToken = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          await axios
            .get(
              `https://aware.y-f.info/twitter/draft/delete/post/${decoded.id}/${postId}`
            )
            .then(() => {
              getDraftPosts();
            });
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };
    try {
      await axios.delete(
        `https://aware.y-f.info/twitter/draft/delete/post/{id}/{postId}` +
          postId
      );
      console.log("Delete Post with ID: ", postId);
      getDraftPosts();
    } catch (err) {
      console.log(err);
    }
    getToken();
  };

  const setDraftUpdate = async () => {
    seteditdraftId(postId);
    console.log("Draft Id: ", postId);
    setNewPost(posts[index][0]);
    setdraftEdit(true);
    if (isMobile) {
      await setPostMobile(true);
    } else {
      await openModal();
    }
  };

  return (
    <div className="mx-3 sm:mx-10 py-4 lg:py-10">
      <div className="flex flex-row gap-x-4 relative place-content-between">
        <div className="max-w-3/4 mx-4 flex flex-col lg:flex-row relative">
          <div className="m-auto font-bold">Draft {index + 1}</div>
          <div className="hidden lg:flex">&nbsp;&nbsp; </div>
          <div className="m-auto text-xs text-gray">{date}</div>
        </div>

        <div className="flex flex-row gap-x-5 mx-5">
          {/*
           * Delete & Edit Buttons Popover
           * Transition element defines the animation
           */}
          <button
            onClick={startAnalyse}
            className="mx-3 max-w-max flex self-end"
          >
            <img
              className={`min-w-px max-w-px ${
                isMobile && analyseLoad && "animate-pulse"
              }`}
              src={
                analyse && activeButton
                  ? "./imgs/Analysed.svg"
                  : "./imgs/analyseButtonF.svg"
              }
              alt="analyse"
            />
          </button>

          <Popover className="relative self-end">
            <Popover.Button>
              <TripleDots />
            </Popover.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-75 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel className="absolute right-0.5 z-10 bg-gray-50 rounded-lg transition duration-150">
                <div className="flex flex-col divide-y px-4">
                  <button
                    className="text-right flex flex-row gap-x-4 self-start w-full py-2"
                    onClick={deleteDraft}
                  >
                    <DeleteIcon /> Delete
                  </button>

                  <button
                    className="text-right flex flex-row gap-x-4 self-start w-full py-2"
                    onClick={setDraftUpdate}
                  >
                    <EditIcon /> Edit
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>

        {/* {posts.length <= 1 ? (
          ""
        ) : (
          <img
            className="ml-auto sm:mr-24 justify-end self-start cursor-pointer"
            src="./imgs/dropdown.svg"
            alt="dropdown"
            onClick={() => deletePostHandler()}
          />
        )} */}
      </div>

      {posts.length < 0 ? (
        <></>
      ) : (
        <div className="mx-4 my-5 grid grid-flow-row gap-y-5 whitespace-pre-wrap">
          <HighlightNew
            allIssues={allIssues}
            active={activeButton}
            textToHighlight={textToHighlight}
            showText={showText}
            setText={setText}
          />
        </div>
      )}
    </div>
  );
}

export default ViewDraft;
