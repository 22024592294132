import React from "react";

const LinkedInIcon = ({ fill, mobile, notifications }) => {
  return (
    <div className="relative">
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 0H1C0.446875 0 0 0.46475 0 1.04V24.96C0 25.5352 0.446875 26 1 26H24C24.5531 26 25 25.5352 25 24.96V1.04C25 0.46475 24.5531 0 24 0ZM7.41562 22.1552H3.70625V9.74675H7.41562V22.1552ZM5.5625 8.05025C5.13727 8.05025 4.72159 7.91911 4.36802 7.67342C4.01446 7.42772 3.73889 7.07851 3.57616 6.66993C3.41343 6.26135 3.37085 5.81177 3.45381 5.37803C3.53677 4.94429 3.74154 4.54587 4.04222 4.23316C4.3429 3.92045 4.726 3.70749 5.14306 3.62121C5.56011 3.53494 5.99241 3.57922 6.38527 3.74846C6.77813 3.91769 7.11391 4.20429 7.35016 4.57199C7.5864 4.9397 7.7125 5.37201 7.7125 5.81425C7.70937 7.04925 6.74687 8.05025 5.5625 8.05025ZM21.3031 22.1552H17.5969V16.12C17.5969 14.6802 17.5719 12.831 15.6688 12.831C13.7406 12.831 13.4438 14.3975 13.4438 16.016V22.1552H9.74063V9.74675H13.2969V11.4432H13.3469C13.8406 10.4683 15.05 9.438 16.8563 9.438C20.6125 9.438 21.3031 12.0087 21.3031 15.3497V22.1552Z"
          fill={fill}
        />
      </svg>
      {mobile && (
        <span className="bg-red-500 text-red-50 py-1 px-2 rounded-full text-xs absolute -top-3 -right-3 ml-1">
          {notifications}
        </span>
      )}
    </div>
  );
};

export default LinkedInIcon;
