import { useState, Fragment, useEffect } from "react";
import jwt from "jsonwebtoken";
import axios from "axios";

import langPref from "../../util/langPref";

import SocialPill from "../common/SocialPill";
import SocialPillMobile from "../common/SocialPillMobile";
import Dashboard from "../assets/Icons/Dashboard";
import FacebookIcon from "../assets/Icons/FacebookIcon";
import InstagramIcon from "../assets/Icons/InstagramIcon";
import LinkedInIcon from "../assets/Icons/LinkedInIcon";
import TwitterIcon from "../assets/Icons/TwitterIcon";
import AddAccIcon from "../assets/Icons/AddAccIcon";
import ProfileIcon from "../assets/Icons/ProfileIcon";
import AccountIcon from "../assets/Icons/AccountIcon";
import NotificationIcon from "../assets/Icons/NotificationIcon";
import PasswordIcon from "../assets/Icons/PasswordIcon";
import LogoutIcon from "../assets/Icons/LogoutIcon";

function AccountSidebar({ selected, setSelected }) {
  const [accountType, setAccountType] = useState("");

  const [profile_setting, setProfile_setting] = useState("");
  const [account_setting, setAccount_setting] = useState("");
  const [notification_setting, setNotification_setting] = useState("");
  const [password_update, setPassword_update] = useState("");
  const [logout, setLogout] = useState("");

  useEffect(() => {
    const fetchProfileDetails = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        //axios to fetch information
        var profFetchConfig = {
          method: "get",
          url: `https://aware.y-f.info/api/user/profile/${decoded.id}`,
        };
        axios(profFetchConfig)
          .then((response) => {
            setAccountType(response.data.profileType);
            const dataList = langPref(response.data.languagePref);
            setProfile_setting(dataList[0].profile_setting);
            setAccount_setting(dataList[0].account_setting);
            setNotification_setting(dataList[0].notification_setting);
            setPassword_update(dataList[0].password_update);
            setLogout(dataList[0].logout);
          })
          .catch((err) => {});
      }
    };
    fetchProfileDetails();

    // return () => {
    //   console.log("unmounted");
    // };
  }, []);
  return (
    <>
      <section className="h-screen sticky top-0 min-w-max hidden md:block bg-gray-50 w-auto 2xl:w-auto shadow-xl">
        <div className="flex flex-col md:px-2 gap-y-5 pt-2 mt-10 justify-center">
          <SocialPill
            collapse={false}
            setSelected={setSelected}
            selected={selected}
            icon={<ProfileIcon fill={"#8B8B8B"} />}
            text={"Profile Settings"}
            social={false}
            title={profile_setting}
          />
          <SocialPill
            collapse={false}
            setSelected={setSelected}
            selected={selected}
            icon={<AccountIcon fill={"#8B8B8B"} />}
            text={"Account Settings"}
            social={false}
            title={account_setting}
          />
          <SocialPill
            collapse={false}
            setSelected={setSelected}
            selected={selected}
            icon={<NotificationIcon fill={"#8B8B8B"} />}
            text={"Notification Settings"}
            social={false}
            title={notification_setting}
          />
          {accountType == "ssShare" ? (
            <SocialPill
              collapse={false}
              setSelected={setSelected}
              selected={selected}
              icon={<PasswordIcon fill={"#8B8B8B"} />}
              text={"Password Update"}
              social={false}
              title={password_update}
            />
          ) : (
            ""
          )}

          <SocialPill
            collapse={false}
            setSelected={setSelected}
            selected={selected}
            icon={<LogoutIcon fill={"#8B8B8B"} />}
            text={"Logout"}
            social={false}
            title={logout}
          />
        </div>
      </section>
{/*       <section className="sm:hidden z-10 bg-ss-black fixed bottom-0 w-full pt-3 rounded-t-xl">
        <div className="grid grid-flow-col justify-evenly">
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <Dashboard fill={"#C2CFE0"} mobile={false} notifications={0} />
            }
            text={"Overview"}
            social={false}
            notifications={0}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <FacebookIcon fill={"#C2CFE0"} mobile={true} notifications={2} />
            }
            text={"Facebook"}
            social={true}
            notifications={2}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <InstagramIcon fill={"#C2CFE0"} mobile={true} notifications={3} />
            }
            text={"Instagram"}
            social={true}
            notifications={3}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <LinkedInIcon fill={"#C2CFE0"} mobile={true} notifications={10} />
            }
            text={"LinkedIn"}
            social={true}
            notifications={10}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <TwitterIcon fill={"#C2CFE0"} mobile={true} notifications={""} />
            }
            text={"Twitter"}
            social={true}
            notifications={""}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <AddAccIcon fill={"#C2CFE0"} mobile={false} notifications={0} />
            }
            text={"Add accounts"}
            social={false}
            notifications={0}
          />
        </div>
      </section> */}
    </>
  );
}

export default AccountSidebar;
