import React from "react";

const PasswordIcon = ({ fill }) => {
  return (
    <>
      <svg
        className={`fill-current text-${fill}`}
        width="26"
        height="29"
        viewBox="0 0 26 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.625 0C11.3805 0 9.30101 0.477946 7.34414 1.01C5.34218 1.55107 3.32399 2.19134 2.13724 2.5791C1.64106 2.74296 1.20117 3.04346 0.868098 3.44608C0.535026 3.8487 0.322284 4.33711 0.254313 4.85521C-0.820614 12.9298 1.67372 18.914 4.70011 22.8729C5.9835 24.5663 7.51373 26.0577 9.23969 27.297C9.93587 27.7894 10.5815 28.1663 11.1298 28.4242C11.6348 28.6623 12.1777 28.8571 12.625 28.8571C13.0723 28.8571 13.6133 28.6623 14.1202 28.4242C14.7811 28.103 15.4135 27.7259 16.0103 27.297C17.7363 26.0577 19.2665 24.5664 20.5499 22.8729C23.5763 18.914 26.0706 12.9298 24.9957 4.85521C24.9278 4.33686 24.7151 3.84817 24.3821 3.44524C24.049 3.04231 23.6091 2.74148 23.1127 2.5773C21.39 2.01245 19.6538 1.48926 17.9058 1.0082C15.949 0.479749 13.8695 0 12.625 0ZM12.625 9.01784C13.2639 9.01689 13.8825 9.24208 14.3713 9.65353C14.86 10.065 15.1874 10.6361 15.2954 11.2658C15.4034 11.8955 15.2851 12.5431 14.9614 13.094C14.6377 13.6448 14.1295 14.0632 13.5268 14.2752L14.2212 17.8643C14.2464 17.9949 14.2425 18.1294 14.2097 18.2583C14.1769 18.3871 14.116 18.5071 14.0313 18.6097C13.9467 18.7122 13.8404 18.7948 13.7202 18.8515C13.5999 18.9081 13.4686 18.9375 13.3356 18.9375H11.9144C11.7816 18.9372 11.6505 18.9077 11.5304 18.8509C11.4103 18.7941 11.3043 18.7115 11.2199 18.609C11.1355 18.5064 11.0747 18.3865 11.042 18.2578C11.0093 18.1291 11.0054 17.9947 11.0306 17.8643L11.7232 14.2752C11.1205 14.0632 10.6123 13.6448 10.2886 13.094C9.96487 12.5431 9.84654 11.8955 9.95455 11.2658C10.0626 10.6361 10.3899 10.065 10.8787 9.65353C11.3675 9.24208 11.9861 9.01689 12.625 9.01784Z"
        />
      </svg>
    </>
  );
};

export default PasswordIcon;
