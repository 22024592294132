function PostButton({ btnIcon, btnText }) {
  return (
    <button className="hover:bg-gray-100 text-gray-500 p-2 xl:p-4 inline-flex items-center rounded-xl text-xs xl:text-base font-light transition duration-150">
      <img className="mr-0.5 sm:mr-4" src={btnIcon} alt="btnText" />
      {btnText}
    </button>
  );
}

export default PostButton;
