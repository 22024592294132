import React from "react";

const AddAccIcon = ({ fill }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0625 10.9375V0H10.9375V10.9375H0V14.0625H10.9375V25H14.0625V14.0625H25V10.9375H14.0625Z"
        fill={fill}
      />
    </svg>
  );
};

export default AddAccIcon;
