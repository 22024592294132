import React from "react";

const Tooltip = ({ children, tooltipText, disabled, disabledText }) => {
    const tipRef = React.createRef(null);
    function handleMouseEnter() {
        tipRef.current.style.opacity = 1;
        tipRef.current.style.marginRight = "20px";
        tipRef.current.style.marginDown = "20px";
    }
    function handleMouseLeave() {
        tipRef.current.style.opacity = 0;
        tipRef.current.style.marginRight = "10px";
        tipRef.current.style.marginDown = "10px";
    }
    return (
        <>
        {disabled ?        
            <div
                className="relative flex items-center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                    <div
                        className="absolute text-sm whitespace-nowrap bg-gradient-to-r from-black to-gray-700 text-white px-4 py-2 rounded flex items-center transition-all duration-150"
                        style={{ right:"10%", top:"90%", opacity: 0 }}
                        ref={tipRef}
                    >
                        {disabledText}
                    </div>
                {children}
            </div>
        :
            <div
                className="relative flex items-center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className="absolute text-sm whitespace-nowrap bg-gradient-to-r from-black to-gray-700 text-white px-4 py-2 rounded flex items-center transition-all duration-150"
                    style={{ right:"10%", top:"90%", opacity: 0 }}
                    ref={tipRef}
                >
                    {tooltipText}
                </div>
                {children}
            </div>
            }
        
        </>
    );
}

export default Tooltip;