import { useState, useEffect } from "react";

const removeSpace = (replaceString) => {
  console.log(replaceString);
  var sepecialCharacters = /\s(\W)/;
  // console.log("i am in" , replaceString)
  if (replaceString === ":)" ||
    replaceString === ":(" ||
    replaceString === ";)" ||
    replaceString === ";(" ||
    replaceString === ":D" ||
    replaceString === ";D" ||
    replaceString === ";P" ||
    replaceString === ":p" ||
    replaceString === ";p" ||
    replaceString === ":p"
  ) {
    return replaceString;
  } else return replaceString.replace(sepecialCharacters, "");
};
export default function Highlight({
  showText,
  setText,
  allIssues,
  active,
  textToHighlight,
}) {
  const [textparah, setTextParah] = useState(textToHighlight);

  let highlightClass = [
    { type: "PERSON", class: "bg-ss-entity-blue rounded-md px-1" },
    { type: "EMAIL", class: "bg-ss-entity-yellow rounded-md px-1" },
    { type: "CCNR", class: "bg-ss-hotpink rounded-md px-1" },
    { type: "LOC", class: "bg-ss-entity-brown rounded-md px-1" },
    { type: "POSPOL", class: "text-ss-sentiment-green font-medium" },
    { type: "NEGPOL", class: "text-ss-sentiment-red  font-medium" },
    { type: "URL", class: "underline font-medium" },
  ];

  const getClassforBg = (type) => {
    let myclass = highlightClass.find((data) => data.type === type);
    return myclass.class;
  };

  function highlightText(str) {
    try {
      let z = "<div>" + textToHighlight + "</div>";
      str.forEach((elem) => {
        elem.key = elem.key.replaceAll("!", "");
        var keyword = removeSpace(elem.key);
        if (!/:|;/.test(keyword))
          var regex = new RegExp("\\b" + keyword + "\\b", "gi");
        else var regex = keyword;
        if (elem.type === "URL") {
          z = z.replaceAll(
            elem.key,
            `<span class="${ getClassforBg(elem.type) }" >${ removeSpace(
              elem.key
            ) }</span>`
          );
        } else {
          z = z.replaceAll(
            regex,
            `<span class="${ getClassforBg(elem.type) }" >${ removeSpace(
              elem.key
            ) }</span>`
          );
        }
      });

      setTextParah(z);
    } catch (e) {
      console.log("All Issues Cleared!");
    }
  }

  useEffect(() => {
    if (active) {
      if (showText.length > 0) {
        highlightText(showText);
      } else {
        highlightText(allIssues);
      }
    } else {
      setTextParah(textToHighlight);
    }
  }, [allIssues, showText]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: textparah }}
      className="mt-2 whitespace-pre-wrap break-all"
    />
  );
}