import ChevronDownIcon from "../assets/Icons/ChevronDownIcon";
import MediaIcon from "../assets/Icons/MediaIcon";

function OverallMedia({ amt, open }) {
  if(amt>0){
    return (
      <div className="bg-white grid mt-3 rounded-lg py-4 px-4 shadow-xl">
        <div className="flex flex-row gap-x-6 place-content-between">
          <div className="flex flex-row gap-x-4">
            <MediaIcon />
            <h5 className="self-center">External Media Source</h5>
            <h4 className="not-italic font-semibold p-1 w-8 h-8 rounded-full bg-gray-400 text-black self-center">{amt}</h4>
          </div>
          <div className="flex flex-row self-center">
            <p className="text-ss-grey">URL Detected</p>
            <ChevronDownIcon open={open}/>
          </div>
        </div>
      </div>
    );
  }else{
    return(<></>)
  }
}

export default OverallMedia;
