const LoadingSymbol = ({ width, height }) => {
    return (
        <div className="fixed self-center inset-92 top-80 lg:top-64 z-50">
            <svg width={width} height={height} class="animate-spin ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="purple" stroke-width="2"></circle>
                <path class="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    );
}

export default LoadingSymbol;