import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="sticky top-0 pl-2 py-4 bg-white grid grid-flow-col shadow-sm z-50 mb-5 md:mb-0 w-full">
      
      <div className="flex lg:hidden place-content-between items-center sm:z-10 px-3">
        <Link className="flex flex-row" to="/">
          <img className="h-12 w-12" src="logo.png" alt="logo" />
          <h3 className="text-ss-blue ml-4 text-lg font-bold self-center">ShareAware</h3>
        </Link>
        <Link className="lg:hidden self-center mx-5" to="/myaccount">
          <img src="/imgs/settings.svg" width={25} alt="settings"/>
        </Link>
      </div>

      <div className="px-3 hidden lg:flex z-10 flex-grow gap-x-48 items-center text-gray-400">
        <Link className="flex flex-row" to="/">
          <img className="h-12 w-12" src="logo.png" alt="logo" />
          <h3 className="text-ss-blue ml-4 text-lg font-bold self-center">ShareAware</h3>
        </Link>
        <div className="flex flex-row gap-x-48 self-center">
          <Link className="hover:text-gray-500 transition duration-150" to="#">
            <p>What's New</p>
          </Link>
          <Link className="hover:text-gray-500 transition duration-150" to="#">
            <p>Help</p>
          </Link>
          <Link className="hover:text-gray-500 transition duration-150" to="#">
            <p>Support</p>
          </Link>
          <Link className="hover:text-gray-500 transition duration-150" to="/myaccount">
            <p>My Account</p>
          </Link> 
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
