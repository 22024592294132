import React from "react";

const Sad = ({ selected }) => {
  return (

    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"
      className={`cursor-pointer fill-current ${ selected ? "text-white" : "text-red-500"
        }`}>
      <path opacity="0.1" d="M11.0664 2.23064C6.17499 2.23064 2.21313 6.2235 2.21313 11.1532C2.21313 16.083 6.17499 20.0758 11.0664 20.0758C15.9579 20.0758 19.9197 16.083 19.9197 11.1532C19.9197 6.2235 15.9579 2.23064 11.0664 2.23064ZM14.9398 6.69193C15.8583 6.69193 16.5998 7.4392 16.5998 8.36492C16.5998 9.29064 15.8583 10.0379 14.9398 10.0379C14.0212 10.0379 13.2798 9.29064 13.2798 8.36492C13.2798 7.4392 14.0212 6.69193 14.9398 6.69193ZM7.19312 6.69193C8.11165 6.69193 8.85311 7.4392 8.85311 8.36492C8.85311 9.29064 8.11165 10.0379 7.19312 10.0379C6.27459 10.0379 5.53312 9.29064 5.53312 8.36492C5.53312 7.4392 6.27459 6.69193 7.19312 6.69193ZM14.8844 17.2875C14.1098 15.9603 12.7043 15.0569 11.0664 15.0569C9.42858 15.0569 8.01205 15.9603 7.24845 17.2875H5.40032C5.84496 16.1375 6.62375 15.1496 7.63474 14.4532C8.64573 13.7567 9.84182 13.384 11.0664 13.384C12.2911 13.384 13.4871 13.7567 14.4981 14.4532C15.5091 15.1496 16.2879 16.1375 16.7326 17.2875H14.8844V17.2875Z"  />
      <path d="M14.9401 10.0379C15.8569 10.0379 16.6001 9.2889 16.6001 8.36493C16.6001 7.44097 15.8569 6.69195 14.9401 6.69195C14.0234 6.69195 13.2802 7.44097 13.2802 8.36493C13.2802 9.2889 14.0234 10.0379 14.9401 10.0379Z"  />
      <path d="M7.19344 10.0379C8.11023 10.0379 8.85344 9.2889 8.85344 8.36493C8.85344 7.44097 8.11023 6.69195 7.19344 6.69195C6.27665 6.69195 5.53345 7.44097 5.53345 8.36493C5.53345 9.2889 6.27665 10.0379 7.19344 10.0379Z"  />
      <path d="M11.0556 0C4.94678 0 0 4.99665 0 11.1532C0 17.3098 4.94678 22.3065 11.0556 22.3065C17.1754 22.3065 22.1333 17.3098 22.1333 11.1532C22.1333 4.99665 17.1754 0 11.0556 0ZM11.0666 20.0758C6.17518 20.0758 2.21333 16.083 2.21333 11.1532C2.21333 6.2235 6.17518 2.23065 11.0666 2.23065C15.9581 2.23065 19.9199 6.2235 19.9199 11.1532C19.9199 16.083 15.9581 20.0758 11.0666 20.0758ZM11.0666 13.3839C8.4881 13.3839 6.28585 15.0011 5.40051 17.2875H7.24864C8.01224 15.9603 9.42877 15.0569 11.0666 15.0569C12.7045 15.0569 14.11 15.9603 14.8846 17.2875H16.7327C15.8474 15.0011 13.6452 13.3839 11.0666 13.3839V13.3839Z"  />
    </svg>

  );
};

export default Sad;
