const langPref = (language) => {
  let pack = [];
  switch (language) {
    case "English":
      pack = [
        // tabs
        {
          profile_setting: "Profile Settings",
          account_setting: "Account Settings",
          notification_setting: "Notification Settings",
          password_update: "Password Update",
          logout: "Logout",
        },
        //profile setting
        {
          profile_setting: "Profile Settings",
          basic_information: "Basic Information",
          name: "Name",
          email: "Email Address",
          language: "Language and Time",
          language_and_time: "Language and Time",
          update_language: "Update Language",
          time: "Time",
          time_details_1: "24-hour time",
          time_details_2:
            "Display time in 24-hour format in all features.Enable 24 hr time",

          timer_setting: "Timer Setting",
          timer_setting_details_1: "Update Your timer Setting",
          timer_setting_details_2:
            "Timer setting ranging from 3 seconds to 60 seconds or 0 (completely turn off)",
          timer_setting_details_3: "Update",

          whitelist_term: "Add New WhiteList Terms",
          whitelist_term_details: "Add",
          analyze_option: "Analyze Options",
          analyze_option_details_1: "Update Analyze Option",
          analyze_option_details_2:
            "Disable the categories that you dont want to analyze",
          analyze_option_details_3: "Email",
          analyze_option_details_4: "Name",

          added_whitelist: "WhiteList Terms",
          added_whitelist_detail_1: "Current WhiteList Terms",
          added_whitelist_detail_2:
            "WhiteList terms can be added here directly or by click on the button when analyzing",

          list_of_name: "List of Name",
          list_of_privacy: "List of Private Info",
          list_of_links: "List of Links",

          remove: "Remove",
        },
        //account setting
        {
          account_setting: "Account Settings",
          facebook: "Facebook",
          instagram: "Instagram",
          linkedin: "LinkedIn",
          twitter: "Twitter",
          pinterest: "Pinterest",
        },
        // notification setting
        {
          notification_setting: "Notification Settings",
        },
        // password update
        { password_update: "Password Update" },
        //navbar
      ];
      break;
    case "Mandarin":
      pack = [
        // tabs
        {
          profile_setting: "配置文件设置",
          account_setting: "帐号设定",
          notification_setting: "通知设置",
          password_update: "密码更新",
          logout: "登出",
        },
        //profile setting
        {
          profile_setting: "配置文件设置",
          basic_information: "基本信息",
          name: "名称",
          email: "电子邮件地址",
          language: "语言和时间",
          language_and_time: "语言和时间",
          update_language: "更新语言",
          time: "时间",
          time_details_1: "24 小时制",
          time_details_2: "在所有功能中以 24 小时格式显示时间。启用 24 小时制",

          timer_setting: "定时器设置",
          timer_setting_details_1: "更新您的计时器设置",
          timer_setting_details_2:
            "定时器设置范围从 3 秒到 60 秒或 0(完全关闭)",
          timer_setting_details_3: "更新",
          whitelist_term: "添加新的白名单条款",
          whitelist_term_details: "添加",
          analyze_option: "分析选项",
          analyze_option_details_1: "更新分析选项",
          analyze_option_details_2: "禁用您不想分析的类别",
          analyze_option_details_3: "电子邮件",
          analyze_option_details_4: "姓名",
          added_whitelist: "白名单条款",
          added_whitelist_detail_1: "当前的白名单条款",
          added_whitelist_detail_2:
            "白名单条款可以在这里直接添加，也可以在分析时点击按钮",
          list_of_name: "名单",
          list_of_privacy: "私人信息列表",
          list_of_links: "链接列表",

          remove: "消除",
        },
        //account setting
        {
          account_setting: "帐号设定",
          facebook: "Facebook",
          instagram: "Instagram",
          linkedin: "LinkedIn",
          twitter: "Twitter",
          pinterest: "Pinterest",
        },
        // notification setting
        {
          notification_setting: "通知设置",
        },
        // password update
        { password_update: "密码更新" },
        //navbar
      ];
      break;
    case "German":
      pack = [
        // tabs
        {
          profile_setting: "Profileinstellungen",
          account_setting: "Account Einstellungen",
          notification_setting: "Benachrichtigungseinstellungen",
          password_update: "Kennwortaktualisierung",
          logout: "Ausloggen",
        },
        //profile setting
        {
          profile_setting: "Profileinstellungen",
          basic_information: "Grundinformation",
          name: "Name",
          email: "E-Mail-Addresse",
          language: "Sprache und Zeit",
          language_and_time: "Sprache und Zeit",
          update_language: "Sprache aktualisieren",
          time: "Zeit",
          time_details_1: "24-Stunden-Zeit",
          time_details_2:
            "Zeit in allen Funktionen im 24-Stunden-Format anzeigen. 24-Stunden-Zeit aktivieren",

          timer_setting: "Timer-Einstellung",
          timer_setting_details_1: "Aktualisieren Sie Ihre Timer-Einstellung",
          timer_setting_details_2:
            "Timer-Einstellung von 3 Sekunden bis 60 Sekunden oder 0 (vollständig ausschalten)",
          timer_setting_details_3: "Aktualisieren",
          whitelist_term: "Fügen Sie neue Whitelist-Begriffe hinzu",
          whitelist_term_details: "Addieren",
          analyze_option: "Optionen analysieren",
          analyze_option_details_1: "Analyseoption aktualisieren",
          analyze_option_details_2:
            "Deaktivieren Sie die Kategorien, die Sie nicht analysieren möchten",
          analyze_option_details_3: "Email",
          analyze_option_details_4: "Name",
          added_whitelist: "WhiteList-Bedingungen",
          added_whitelist_detail_1: "Aktuelle Whitelist-Bedingungen",
          added_whitelist_detail_2:
            "WhiteList-Begriffe können hier direkt oder per Klick auf die Schaltfläche bei der Analyse hinzugefügt werden",
          list_of_name: "Namensliste",
          list_of_privacy: "Liste des informations privées",
          list_of_links: "Liste der Links",

          remove: "Entfernen",
        },
        //account setting
        {
          account_setting: "Account Einstellungen",
          facebook: "Facebook",
          instagram: "Instagram",
          linkedin: "LinkedIn",
          twitter: "Twitter",
          pinterest: "Pinterest",
        },
        // notification setting
        {
          notification_setting: "Benachrichtigungseinstellungen",
        },
        // password update
        { password_update: "Kennwortaktualisierung" },
        //navbar
      ];
      break;
    case "Japanese":
      pack = [
        // tabs
        {
          profile_setting: "プロファイル設定",
          account_setting: "アカウント設定",
          notification_setting: "通知設定",
          password_update: "パスワードの更新",
          logout: "ログアウト",
        },
        //profile setting
        {
          profile_setting: "プロファイル設定",
          basic_information: "基本情報",
          name: "名前",
          email: "電子メールアドレス",
          language: "言語と時間",
          language_and_time: "言語と時間",
          update_language: "言語の更新",
          time: "時間",
          time_details_1: "24時間",
          time_details_2:
            "すべての機能で24時間形式で時間を表示します.24時間を有効にします",

          timer_setting: "タイマー設定",
          timer_setting_details_1: "タイマー設定を更新する",
          timer_setting_details_2:
            "「3秒から60秒、または0 (完全にオフ)までのタイマー設定",
          timer_setting_details_3: "更新",

          whitelist_term: "新しいホワイトリスト用語の追加",
          whitelist_term_details: "追加",

          analyze_option: "アナライズオプション",
          analyze_option_details_1: "解析オプションの更新",
          analyze_option_details_2: "分析したくないカテゴリを無効にする",
          analyze_option_details_3: "メール",
          analyze_option_details_4: "名前",

          added_whitelist: "ホワイトリスト用語",
          added_whitelist_detail_1: "現在のホワイトリスト条件",
          added_whitelist_detail_2:
            "WhitListの用語はここに直接、または解析時にボタンをクリックして追加することができます",

          list_of_name: "名前のリス トです",
          list_of_privacy: "個人情報一覧",
          list_of_links: "リンクのリスト",

          remove: "削除",
        },
        //account setting
        {
          account_setting: "アカウント設定",
          facebook: "Facebook",
          instagram: "Instagram",
          linkedin: "LinkedIn",
          twitter: "Twitter",
          pinterest: "Pinterest",
        },
        // notification setting
        {
          notification_setting: "通知設定",
        },
        // password update
        { password_update: "パスワードの更新" },
        //navbar
      ];
      break;
    default:
      pack = [
        // tabs
        {
          profile_setting: "Profile Settings",
          account_setting: "Account Settings",
          notification_setting: "Notification Settings",
          password_update: "Password Update",
          logout: "Logout",
        },
        //profile setting
        {
          profile_setting: "Profile Settings",
          basic_information: "Basic Information",
          name: "Name",
          email: "Email Address",
          language: "Language and Time",
          language_and_time: "Language and Time",
          update_language: "Update Language",
          time: "Time",
          time_details_1: "24-hour time",
          time_details_2:
            "Display time in 24-hour format in all features.Enable 24 hr time",

          timer_setting: "Timer Setting",
          timer_setting_details_1: "Update Your timer Setting",
          timer_setting_details_2:
            "Timer setting ranging from 3 seconds to 60 seconds or 0 (completely turn off)",
          timer_setting_details_3: "Update",
          whitelist_term: "Add New WhiteList Terms",
          whitelist_term_details: "Add",
          analyze_option: "Analyze Options",
          analyze_option_details_1: "Update Analyze Option",
          analyze_option_details_2:
            "Disable the categories that you dont want to analyze",
          analyze_option_details_3: "Email",
          analyze_option_details_4: "Name",
          added_whitelist: "WhiteList Terms",
          added_whitelist_detail_1: "Current WhiteList Terms",
          added_whitelist_detail_2:
            "WhiteList terms can be added here directly or by click on the button when analyzing",
          list_of_name: "List of Name",
          list_of_privacy: "List of Private Info",
          list_of_links: "List of Links",

          remove: "Remove",
        },
        //account setting
        {
          account_setting: "Account Settings",
          facebook: "Facebook",
          instagram: "Instagram",
          linkedin: "LinkedIn",
          twitter: "Twitter",
          pinterest: "Pinterest",
        },
        // notification setting
        {
          notification_setting: "Notification Settings",
        },
        // password update
        { password_update: "Password Update" },
        //navbar
      ];
  }
  return pack;
};

module.exports = langPref;
