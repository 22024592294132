const MediaIcon = ({ selected }) => {
  return (
    <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2633_2736)">
        <path d="M14.625 19.7281V28.7281L22.5 24.2281L14.625 19.7281Z" fill="#A91717" />
        <path d="M29.25 34.3531H6.75C6.15345 34.3526 5.5815 34.1153 5.15967 33.6935C4.73784 33.2717 4.5006 32.6997 4.5 32.1031V16.3531C4.5006 15.7566 4.73784 15.1846 5.15967 14.7628C5.5815 14.341 6.15345 14.1037 6.75 14.1031H29.25C29.8466 14.1037 30.4185 14.341 30.8403 14.7628C31.2622 15.1846 31.4994 15.7566 31.5 16.3531V32.1031C31.4994 32.6997 31.2622 33.2717 30.8403 33.6935C30.4185 34.1153 29.8466 34.3526 29.25 34.3531ZM6.75 16.3531V32.1031H29.25V16.3531H6.75ZM6.75 9.60315H29.25V11.8531H6.75V9.60315ZM9 5.10315H27V7.35315H9V5.10315Z" fill="#A91717" />
      </g>
      <defs>
        <clipPath id="clip0_2633_2736">
          <rect width="36" height="31.7203" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MediaIcon;
