function MakeDraft({
    name,
    openModal,
    setAnalyse,
    isMobile,
    setPostMobile,
    selected
}) {
    if(selected === "Drafts"){
        return (
            <div className="lg:mx-14 mx-5 sm:mt-10">
                <div>
                    <div className="flex gap-x-4">
                        <input
                            onClick={async () => {
                                if (isMobile) {
                                    await setPostMobile(true);
                                } else {
                                    await openModal();
                                }
                                setAnalyse(false);
                            }}
                            type="text"
                            placeholder={`Type out your draft, ${ name }.`}
                            id="draft"
                            value=""
                            className="mt-2 w-full sm:w-4/6 self-center rounded-lg text-gray-800 bg-gray-100 border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                        />
                    </div>
                </div>
            </div>
        );
    }
    return(<></>);
}

export default MakeDraft;
