import { useEffect, useRef, useState } from "react";
import HighlightNew from "../../common/HighlightNew";
import PostButton from "../../common/PostButton";
import ClipboardButton from "../../common/ClipboardButton";
import axios from "axios";
import jwt from "jsonwebtoken";

function CreatePostMobileModal({
  postMobile,
  setPostMobile,
  currentUser,
  newPost,
  setNewPost,
  searchTerm,
  setSearchTerm,
  setPosts,
  allPosts,
  setAnalyse,
  analyse,
  setCurrentIndex,
  keywords,
  setKeywords,
  posts,
  currentIndex,
  analyseIssues,
  highlightColor,
  setHighlightColor,
  selected,
  editdraftId,
  draftEdit,
  setdraftEdit,
  getDraftPosts,
  showText,
  setText,
  allIssues,
  setAllIssues,
  getTwitterPosts
}) {
  // showing/hiding React Highlighter component based on post edit
  const [hideTextbox, setHideTextbox] = useState(false);
  // timer for button
  const [timer, setTimer] = useState(5);
  const [timerOn, setTimerOn] = useState(false);

  const fetchProfileDetails = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      //axios to fetch information
      var profFetchConfig = {
        method: "get",
        url: `https://aware.y-f.info/api/user/profile/${decoded.id}`,
      };
      axios(profFetchConfig)
        .then((response) => {
          setTimer(response.data.timerData);
        })
        .catch((err) => {
          alert("error fetching");
        });
    }
  };

  function removeImage(index) {
    setImgPreviews();
  }

  const submitPost = async () => {
    // hardcoded values for testing creation of post
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp("")]);;
    await setHideTextbox(false);
    await setTimerOn(false);
    await setAnalyse(false);
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const getToken = async () => {
        const formData = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            formData.append("username", currentUser.name);
            formData.append("email", currentUser.email);
            formData.append("desc", newPost);
            formData.append("platform", "twitter");

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                formData.append("photo", imgPreviews[i]);
              }
            }

            await axios.post(
              // `https://aware.y-f.info/twitter/post/${decoded.id}`,
              // `https://aware.y-f.info/twitter/upload/image/${decoded.id}`,
              `https://aware.y-f.info/twitter/upload/multiImage/${decoded.id}`,

              formData,
              config
            );
            setImgPreviews();
            getTwitterPosts();
            // let currentUser = res.data;

            // console.log('currentUser', currentUser);
          } catch (err) {
            // console.log(err);
            // localStorage.removeItem("authToken");
            alert("only 4 images max");
            setImgPreviews();
            // window.location.href = "/";
          }
        }
      };

      /* Facebook post axios
			await axios.post(
				'https://aware.y-f.info/facebook/post',
				{
					body: newPost,
				},
				config
			);
			*/
      // for posting '/' in posts.route

      //   for twitter

      // await axios.post(
      //   "https://aware.y-f.info/twitter/post/",
      //   {
      //     username: currentUser.name,
      //     email: currentUser.email,
      //     desc: newPost,
      //     platform: "twitter",
      //   },
      //   config
      // );
      // window.location.replace("/");
      getToken();
    } catch (error) {
      console.log("Post is empty");
    }

    //   await axios.post(
    //     "https://aware.y-f.info/api/post/",
    //     {
    //       username: currentUser.name,
    //       email: currentUser.email,
    //       desc: newPost,
    //     },
    //     config
    //   );
    //   window.location.replace("/");
    // } catch (error) {
    //   console.log("Post is empty");
    // }
  };
  const saveDraft = async () => {
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp("")]);;
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    console.log("press");
    try {
      const getToken = async () => {
        const data = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            console.log("inside");
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            data.append("desc", newPost);

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                data.append("photo", imgPreviews[i]);
              }
            }
            // config
            // var config = {
            //   method: "post",
            //   url: `https://aware.y-f.info/twitter/draft/post/${decoded.id}`,
            //   headers: {
            //     ...data.getHeaders(),
            //   },
            //   data: data,
            // };
            let config = {
              method: "post",
              url: `https://aware.y-f.info/twitter/draft/post/${decoded.id}`,

              data: data,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!", response);
              })
              .catch((error) => {
                console.log("doesnt work", error);
              });
          } catch (err) {
            console.log("hmm", err);
            // window.location.href = "/";
          }
        }
      };
      getToken();
      await setPostMobile(false);
      getDraftPosts();
    } catch (error) {}
  };

  const updateDraft = async () => {
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp("")]);;
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    console.log("press");
    try {
      const getToken = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            console.log("inside");
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            var data = JSON.stringify({
              desc : newPost
            });

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                data.append("photo", imgPreviews[i]);
              }
            }
            // config
            // var config = {
            //   method: "post",
            //   url: `https://aware.y-f.info/twitter/draft/post/${decoded.id}`,
            //   headers: {
            //     ...data.getHeaders(),
            //   },
            //   data: data,
            // };
            let config = {
              method: "post",
              url: `https://aware.y-f.info/twitter/draft/update/${decoded.id}/${editdraftId}`,
              headers:{
                'Content-Type':'application/json'
              },
              data: data,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!", response);
              })
              .catch((error) => {
                console.log("doesnt work", error);
              });
          } catch (err) {
            console.log("hmm", err);
            // window.location.href = "/";
          }
        }
      };
      getToken();
      await setPostMobile(false);
      getDraftPosts();
      getDraftPosts();
    } catch (error) {}
  };

  const startAnalyse = async () => {
    var issues = [];
    document.body.style.cursor = 'wait';

    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      let data = JSON.stringify({
        desc: newPost,
      });

      var startAnalyseconfig = {
        method: "post",
        url: `https://aware.y-f.info/twitter/check/and/tell/${decoded.id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(startAnalyseconfig)
        .then(async (response) => {
          console.log("hmm this is data", response.data);
          const userPost = [
            response.data[0],
            response.data[1],
            response.data[2],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            response.data[3],
            response.data[4],
          ];
          await setPosts([...allPosts, userPost]);
          await setCurrentIndex(allPosts.length);
          await setHideTextbox(true);
          await setAnalyse(!analyse);
          await setKeywords(
            [...response.data[1], ...response.data[2], ...response.data[3], ...response.data[4]].map((word) =>
              RegExp(`\\b${word}\\b`)
            )
          );

          [...response.data[1], ...response.data[2], ...response.data[3], ...response.data[4]].map((word) =>{
            if(word.dataNeeded){
              issues.push({ key: word.dataNeeded.key, type:"URL"});
            }
            else if(word.sentiment){
              if(word.sentiment.por < 0){
                issues.push({key:word.sentiment.sTerm.join(' '), type:"NEGPOL"});
              }else if(word.sentiment.por > 0){
                issues.push({key:word.sentiment.sTerm.join(' '), type:"POSPOL"});
              }
            }
            else{
              issues.push({key:word[0], type:word[1]});
            }
          }
        )
          issues = issues.filter(function (element) {
            return element.key !== undefined;
          });
          setText([]);
          setAllIssues(issues);
          document.body.style.cursor = 'default';

        })
        
      .catch((error) => {});
    }

  };

  useEffect(() => {
    fetchProfileDetails();
    if (analyse) {
      setKeywords([new RegExp("")]);;
      setHideTextbox(true);
      // ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"].map(
      //   (word) => [RegExp(`\\b${word}\\b`)]
      // )
    } else {
      setKeywords([new RegExp("")]);;
      setHideTextbox(false);
    }
  }, [analyse, currentIndex, posts, setKeywords]);
  
  // use hidden buttons with refs for file upload since they are can't be styled
  const hiddenImgInput = useRef(null);
  const hiddenVideoInput = useRef(null);

  // stores the FileObject for images uploaded by user
  const [imgPreviews, setImgPreviews] = useState();

  function removeImage(index) {
    setImgPreviews();
  }

  const handleImage = () => {
    hiddenImgInput.current.click();
  };

  const handleImgPreview = async (event) => {
    await setImgPreviews(event.target.files);
  };

  const handleVideo = () => {
    hiddenVideoInput.current.click();
  };

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);
  
  return (
    <div className="bg-gray-50 overflow-scroll h-full w-full z-10 fixed top-0">
      <div className="flex flex-row justify-between px-4 py-4 bg-white shadow-md">
        <button
          onClick={async () => {
            await setAnalyse(false);
            await setPostMobile(false);
            await setNewPost("");
            await setKeywords([new RegExp("")]);;
          }}
          className="max-w-max max-h-max flex self-center"
        >
          <img src="/imgs/cross.svg" alt="cross" />
        </button>
        {selected !== "Drafts" ?
          <>
            <h2 className="font-bold text-ss-blue self-center">Create Post</h2>
            {analyseIssues && analyse ? (
                <>
                  <div
                    className={` ${
                      timer <= 0 ? "hidden" : "flex"
                    }   gap-x-4`}
                  >
                    <button
                      type="button"
                      className="inline-flex justify-center px-6 py-2 text-sm font-medium text-gray-500 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 transition duration-150 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={submitPost}
                    >
                      Post Now
                    </button>
                    
                    <button
                      type="button"
                      // onClick={() => setTimerOn(true)}
                      disabled={timerOn}
                      className="inline-flex justify-center px-4   py-2 disabled:opacity-50 disabled:cursor-default text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      <div className="inline-flex gap-x-3">
                        <img src="/imgs/timer.svg" alt="timer" />
                        <p className=" self-center">{timer}s </p>
                      </div>
                    </button>
                  </div>
                  <div
                    className={`  ${
                      timer > 0 ? "hidden" : "flex"
                    }  gap-x-4`}
                  >
                    <button
                      type="button"
                      className="inline-flex justify-center px-8 py-2 text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={saveDraft}
                    >
                      Save Draft
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-8 py-2 text-sm font-medium text-white bg-ss-hotpink border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={submitPost}
                    >
                      Post
                    </button>
                  </div>
                </>
              ) : (
                <div className=" flex  gap-x-4 ">
                  <button
                    type="button"
                    className="inline-flex justify-center px-8 py-2 text-sm font-medium text-gray-500 bg-gray-100 border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={submitPost}
                  >
                    Post Now
                  </button>
                </div>
              )}
          </>
        :
          <>
            {draftEdit !== true ?
                <>
                  <h2 className="font-bold text-ss-blue">Create Draft</h2>
                  <button
                    onClick={saveDraft}
                    type="button"
                    className="justify-center px-6 py-1 text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md transition duration-150 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    Save Draft
                  </button>
                </>
            :
                <>

                  <h2 className="font-bold text-ss-blue">Edit Draft</h2>
                  <button
                    onClick={updateDraft}
                    type="button"
                    className="justify-center px-6 py-1 text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md transition duration-150 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    Save Edit
                  </button>

              </>
          }
          </>
        }
      </div>
      <div className="flex flex-row mt-10 mx-4 gap-x-4">
          <div className="flex flex-row place-items-between gap-x-5 text-gray-400">
            <p className="text-ss-blue text-lg font-bold">{currentUser.name}</p>
            <button
                onClick={function(event){ startAnalyse(); setTimerOn(true)}}
                className="max-w-max max-h-px flex "
              >
                <img
                  width="100"
                  src={
                    analyse
                      ? "./imgs/analyseButtonT.svg"
                      : "./imgs/analyseButtonF.svg"
                  }
                  alt="analyse false"
                />
            </button>
          {/* <div className="inline-flex mt-2">
            <div onClick={handleVideo}>
              <PostButton btnIcon="./imgs/video.svg" btnText="Upload Videos" />
              <input
                type="file"
                accept="video/*"
                ref={hiddenVideoInput}
                hidden
              />
            </div>
            <div onClick={handleImage}>
              <PostButton btnIcon="./imgs/photo.svg" btnText="Upload Photos" />
              <input
                onChange={(event) => handleImgPreview(event)}
                type="file"
                accept="image/*"
                multiple
                ref={hiddenImgInput}
                hidden
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col gap-y-4 mt-8 mx-2">
        <textarea
          autoFocus          
          rows="5"
          type="text"
          placeholder={`What's on your mind, ${currentUser.name}?`}
          id="post"
          value={newPost}
          onChange={(event) => setNewPost(event.target.value)}
          className={`${
            hideTextbox ? "hidden" : ""
          } mt-2 leading-relaxed w-full self-center rounded-lg text-gray-800 bg-gray-50 border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50`}
        ></textarea>
        <ClipboardButton text={newPost} />
        <div
          className={`${hideTextbox ? "mx-2 mt-3" : "hidden"}`}
          onClick={async () => {
            await setHideTextbox(false);
            await setAnalyse(false);
          }}
        >
          <HighlightNew
              allIssues = {allIssues}
              active = {analyse}
              textToHighlight = {newPost}
              showText = {showText}
              setText = {setText}
            />
        </div>
      </div>
      <div className="grid gap-y-10 mt-10">
        {imgPreviews &&
          [...imgPreviews].map((img, index) => {
            return (
              <div className="relative">
                <div className="absolute top-0 right-0 bg-red-500 text-center text-white rounded-full w-4 h-4" onClick={()=>removeImage(index)} > x </div>
                <img key={index} src={URL.createObjectURL(img)} alt="preview" />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CreatePostMobileModal;
