import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
// import OauthHelper from "../../../../backend/utils/OAuthHelper";

export default function FeedbackModal({isOpen, setIsOpen, category, setCategory, issue, setIssue}) {
  const [Feedback, setFeedback] = useState("");

  const postFeedback = async () => {
    setIsOpen(false);
    try {
      const getToken = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            console.log("inside");
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            var data = JSON.stringify({
              "feed_type" : "complain",
              "feed_content": Feedback,
              "category": category,
              "term": issue,
            });
            console.log(data);
            let config = {
              method: "post",
              url: `https://aware.y-f.info/api/user/feedback/send/now/${decoded.id}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!", response);
              })
              .catch((error) => {
                console.log("doesnt work", error);
              });
          } catch (err) {
            console.log("hmm", err);
          }
        }
      };
      getToken();
    } catch (error) {}
  };

  function handleClose(){
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          static
          as="div"
          open={false}
          className="fixed inset-0 right-80 overflow-y-auto"
          onClose={async () => {
            setIsOpen(false);
          }}
        >
          <div className="min-h-full px-4 ml-40 overflow-hidden 2xl:ml-0 2xl:text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed bg-black opacity-60 inset-0 top-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-4/6 2xl:w-3/6 p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <button onClick={handleClose}><img src="/imgs/cross.svg" alt="close" /></button>
                <div className="mx-10 grid grid-flow-row sm:mt-10">
                  <div className="flex flex-row gap-x-4 relative">
                    <div className="flex flex-row relative">
                      <h2>Report Feedback</h2>
                    </div>
                  </div>

                  <div className="flex flex-col gap-y-4 mt-8">
                    <textarea
                      autoFocus                      
                      rows="5"
                      type="text"
                      placeholder={`Report your feedback.`}
                      id="post"
                      onChange={(event) => setFeedback(event.target.value)}
                      className="mt-2 leading-relaxed w-full self-center rounded-lg text-gray-800 bg-gray-50 border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                    ></textarea>
                  </div>

                  <div className="mt-4 flex justify-end gap-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-8 py-2 text-sm font-medium text-white bg-ss-analyseGreen border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={postFeedback}
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
