import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import Analyse from "../AnalyseMenu/Analyse";
import AnalyseMobile from "../AnalyseMenu/AnalyseMobile";
import MakePost from "./MakePost";
import MakeDraft from "./MakeDraft";
import NavBar from "../common/NavBar";
import ViewPost from "./ViewPost";
import ViewDraft from "./ViewDraft";
import SideBar from "../common/SideBar";
import LoadingSymbol from "../common/LoadingSymbol";
import axios from "axios";
import jwt from "jsonwebtoken";
import CreatePostMobileModal from "./PostModal/CreatePostMobileModal";
import CreatePostModal from "./PostModal/CreatePostModal";
import FeedbackModal from "./Feedback/FeedbackModal";
import AddAccountModal from "./AccountModal/AddAccountModal";

function Dashboard() {
  const [selected, setSelected] = useState("Drafts");
  const [isOpen, setIsOpen] = useState(false);
  const [newPost, setNewPost] = useState("");
  const [searchTerm, setSearchTerm] = useState([""]);
  const [analyseIssues, setAnalyseIssues] = useState(false);
  const [postMobile, setPostMobile] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [postsAvailable, setPostsAvailable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [analyseLoad, setAnalyseLoad] = useState(false);
  const [showText, setText] = useState([]);
  
  const [highlightColor, setHighlightColor] = useState(
    "rgba(249, 166, 17, 0.5)"
  );
  const [selectAll, setSelectAll] = useState(true);

  function openModal() {
    setIsOpen(true);
  }
  const [keywords, setKeywords] = useState([new RegExp()]);
  const [allIssues, setAllIssues] = useState([]);

  // toggle for analyse button
  const [analyse, setAnalyse] = useState(false);
  // to keep track of which post is being curently analysed
  const [currentIndex, setCurrentIndex] = useState(0);

  /* Feedback variables */
  const [isFeedback, openFeedback] = useState(false);
  const [feedIssue, setFeedIssue] = useState("");
  const [feedCategory, setFeedCategory] = useState();

  function sendFeedback(issue, category) {
    setFeedIssue(issue);
    setFeedCategory(category);
    openFeedback(true);
  }

  /* Add Account Modal */

  const [accountModal, setAccountModal] = useState(false);

  function openAccountModal() {
    setAccountModal(true);
  }
  //  Dummy Array that describes the structure of the posts state.

  // const [posts, setPosts] = useState([
  //   ["Today is super good, sunny and windy. ", [], []],
  //   [
  //     "Sally's email is bob@example.org. My goodness All wth. How annoying! Bob is a good Allguy, I love him. It's not fair for Sally to steal his email name-right! I am an abc@example.org",
  //     ["Bob", "Sally"],
  //     ["bob@example.org", "All", "abc@example.org", "steal", "name-right"],
  //   ],
  //   [
  //     "What is the difference between art and design? - www.google.com This student lead Q&A covers art vs. design, how to provide value to clients, what to charge for your work, and why you shouldnt worry about being original.",
  //     ["art", "the"],
  //     ["www.google.com"],
  //   ],
  //   [
  //     "What is the difference between art and design? - www.google.com This podcast episode comes from a livestream we hosted via Youtube in early 2020. in it, Chris Do fields questions from a group of design majors visiting from Loyola Marymount Univerisity. The Q&A covers topics like the difference between art and design, demonstrating your value to clients, what to charge for your work, and why there is truly nohing new under the sun.",
  //     ["art", "the"],
  //     ["Youtube in early 2020"],
  //   ],
  //   [
  //     "What is design thinking? How can you better use it to solve problems throughout your life? How did the Washington Monument get cleaner by using this topic? Rather than delivery the same-old-same-solutions to your client’s biggest challenges, The Design Thinking Framework helps you to stand out and provide fresh ways of tackling problems. All with the goal of bringing innovative solution to your clients’ biggest problems. Download the Problem Tree Analysis Template: https//google.com",
  //     ["design", "Washington"],
  //     ["same-old-same-solutions"],
  //   ],
  // ]);

  const [posts, setPosts] = useState([["", [], []]]);
  const [postsUrl, setPostsUrl] = useState("");
  const [postsDate, setPostsDate] = useState("");
  const [postsType, setPostsType] = useState("");
  const [postsProfilePicUrl, setPostsProfilePicUrl] = useState("");
  const [postsUserName, setPostsUserName] = useState("");
  const [postsPlatform, setPostsPlatform] = useState("");
  const [postsId, setPostsId] = useState("");
  const [postUrlScore, setPostUrlScore] = useState();

  // draft
  const [draftPostsId, setdraftPostsId] = useState("");
  const [draftPost, setdraftPost] = useState([["", [], []]]);
  const [draftDate, setdraftDate] = useState("");
  const [draftName, setdraftName] = useState("");
  const [draftEdit, setdraftEdit] = useState(false);
  const [editdraftId, seteditdraftId] = useState("");
  const [draftsAvailable, setDraftsAvailable] = useState(true);

  // for deleting Media/URL cards
  const deleteLink = async (word) => {
    posts[currentIndex][11].map((value, index) => {
      if (value.dataNeeded.key == word) {
        posts[currentIndex][11].splice(index, 1);
      }
    });
    allIssues.map((value, index) => {
      if (value.key === word) {
        allIssues.splice(index, 1);
      }
    });
    await setPosts(posts);
    setText([]);
    setKeywords([new RegExp("")]);
  };

  // for deleting an issue from the privacy cards
  const deletePrivacy = async (word) => {
    posts[currentIndex][1].map((value, index) => {
      if (value[0] === word) {
        console.log("Something",value[0])
        posts[currentIndex][1].splice(index, 1);
      }
    });
    posts[currentIndex][2].map((value, index) => {
      if (value[0] === word) {
        console.log("Something2",value[0], index)
        posts[currentIndex][2].splice(index, 1);
      }
    });
    allIssues.map((value, index) => {
      if (value.key === word) {
        allIssues.splice(index, 1);
      }
    });
    await setPosts(posts);
    setText([]);
    setKeywords([new RegExp("")]);
  };

  // for deleting sentiment cards
  const deleteSentiment = async (word) => {
    posts[currentIndex][12].map((value, index) => {
      if (value.sentiment) {
        if (value["sentiment"].sTerm == word) {
          posts[currentIndex][12].splice(index, 1);
        }
      }
    });
    allIssues.map((value, index) => {
      if (value.key === word) {
        allIssues.splice(index, 1);
      }
    });
    await setPosts(posts);
    setText([]);
    setKeywords([new RegExp("")]);
  };

  const toggleNewUser = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        var config = {
          method: "get",
          url: `https://aware.y-f.info/api/user/onboard/toggle/${decoded.id}`,
        };
        axios(config)
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            alert("error");
          });
      }
    } catch (error) {
      alert("error");
    }
  };
  /* Checks if User has logged in previously */
  const getNewUser = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        var config = {
          method: "get",
          url: `https://aware.y-f.info/api/user/profile/${decoded.id}`,
        };
        axios(config)
          .then((res) => {
            console.log("New User? :", res.data.newUser);
            if (res.data.newUser === true) {
              console.log("Welcome New User!");
              toggleNewUser();
              if (isMobile) {
                window.location.replace("/myaccount/#add-account");
              } else {
                openAccountModal();
              }
            }
          })
          .catch((err) => {
            alert("error");
          });
      }
    } catch (error) {
      alert("error");
    }
  };

  const getOverallPosts = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        var config = {
          method: "get",
          url: `https://aware.y-f.info/overall/get/feed/${decoded.id}`,
        };
        axios(config)
          .then((res) => {
            let tempArray = [];
            let urlList = [];
            let dateList = [];
            let typeList = [];
            let profilePicList = [];
            let userName = [];
            let postPlatform = [];
            let postId = [];
            let rawData = res.data;
            let reference_post = [];
            let urlScore = [];
            for (let i = 0; i < res.data.length; i++) {
              // tempArray.push([res.data[i][0].text]);
              urlList.push(res.data[i][3]);
              dateList.push(res.data[i][4]);
              typeList.push(res.data[i][5]);
              profilePicList.push(res.data[i][6]);
              userName.push(res.data[i][7]);
              postPlatform.push(res.data[i][12]);
              postId.push(res.data[i][10]);
              urlScore.push(res.data[i][11]);

              // console.log("list", res.data[i][3]);

              // 10
            }
            setPostsUrl(urlList);
            setPostsDate(dateList);
            setPostsType(typeList);
            setPostsProfilePicUrl(profilePicList);
            setPostsUserName(userName);
            setPostsPlatform(postPlatform);
            setPostsId(postId);
            // console.log("postsUser", postsUser);
            console.log(rawData);
            setPosts(rawData);
            setPostUrlScore(urlScore);
            setLoading(false);
          })
          .catch((err) => {
            alert("error");
          });
      }
    } catch (error) {
      alert("error");
    }
  };

  const refreshCache = async () => {
    try {
      const token = localStorage.getItem("authToken");
      
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        await axios.get(`https://aware.y-f.info/twitter/feed/${decoded.id}/9`);
      }
    } catch (err) {
      console.log("Cant Update Cache",err);
    }
  }

  /**
   * Revoking permissions on Twitter and attempting to reconnect
   * causes the account to not send any data. Should be looked into on the backend side.
   */
  const getTwitterPosts = async () => {
    setLoading(true);
    refreshCache();
    try {
      setCurrentIndex(0);
      setPosts([["", [], []]]);
      setAnalyse(false);

      // setPosts();
      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        // fetching from twitter api
        const res = await axios.get(
          `https://aware.y-f.info/twitter/feed/${decoded.id}/0`
        );
        let tempArray = [];
        let urlList = [];
        let dateList = [];
        let typeList = [];
        let profilePicList = [];
        let userName = [];
        let postPlatform = [];
        let postId = [];
        let rawData = res.data;
        let reference_post = [];
        let urlScore = [];
        // console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          // tempArray.push([res.data[i][0].text]);
          urlList.push(res.data[i][3]);
          dateList.push(res.data[i][4]);
          typeList.push(res.data[i][5]);
          profilePicList.push(res.data[i][6]);
          userName.push(res.data[i][7]);
          postPlatform.push(res.data[i][12]);
          postId.push(res.data[i][10]);
          urlScore.push(res.data[i][11]);

          // console.log("list", res.data[i][3]);

          // 10
        }
        setPostsUrl(urlList);
        setPostsDate(dateList);
        setPostsType(typeList);
        setPostsProfilePicUrl(profilePicList);
        setPostsUserName(userName);
        setPostsPlatform(postPlatform);
        setPostsId(postId);
        // console.log("postsUser", postsUser);
        setPosts(rawData);
        setPostUrlScore(urlScore);
        setLoading(false);
        // console.log('tempArray', tempArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // fetching from facebook
  const getFacebookPosts = async () => {
    setLoading(true);
    try {
      setCurrentIndex(0);
      setPosts([["", [], []]]);
      setAnalyse(false);

      // setPosts();
      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        var config = {
          method: "get",
          url: `https://aware.y-f.info/facebook/feed/${decoded.id}/0`,
        };
        axios(config)
          .then((res) => {
            let tempArray = [];
            let urlList = [];
            let dateList = [];
            let typeList = [];
            let profilePicList = [];
            let userName = [];
            let postPlatform = [];
            let postId = [];
            let rawData = res.data;
            let reference_post = [];
            let urlScore = [];
            // console.log(res.data);
            for (let i = 0; i < res.data.length; i++) {
              // tempArray.push([res.data[i][0].text]);
              urlList.push(res.data[i][3]);
              dateList.push(res.data[i][4]);
              typeList.push(res.data[i][5]);
              profilePicList.push(res.data[i][6]);
              userName.push(res.data[i][7]);
              postPlatform.push(res.data[i][12]);
              postId.push(res.data[i][10]);
              urlScore.push(res.data[i][11]);

              // console.log("list", res.data[i][3]);

              // 10
            }

            setPostsUrl(urlList);
            setPostsDate(dateList);
            setPostsType(typeList);
            setPostsProfilePicUrl(profilePicList);
            setPostsUserName(userName);
            setPostsPlatform(postPlatform);
            setPostsId(postId);
            // console.log("postsUser", postsUser);
            setPosts(rawData);
            setPostUrlScore(urlScore);
            setLoading(false);
            // console.log('tempArray', tempArray);
          })
          .catch((err) => {});
        // fetching from twitter api
        // const res = await axios.get(
        //   `https://aware.y-f.info/facebook/feed/${decoded.id}/${direc}`
        // );
      }
    } catch (err) {
      console.log(err);
    }
  };
  // get instagram post
  const getInstagramPosts = async () => {
    setLoading(true);
    try {
      setCurrentIndex(0);
      setPosts([["", [], []]]);
      setAnalyse(false);

      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        // setPosts();

        // fetching from twitter api
        const res = await axios.get(
          `https://aware.y-f.info/instagram/feed/${decoded.id}/0`
        );
        let tempArray = [];
        let urlList = [];
        let dateList = [];
        let typeList = [];
        let profilePicList = [];
        let userName = [];
        let postPlatform = [];
        let postId = [];
        let rawData = res.data;
        let reference_post = [];
        let urlScore = [];
        // console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          // tempArray.push([res.data[i][0].text]);
          urlList.push(res.data[i][3]);
          dateList.push(res.data[i][4]);
          typeList.push(res.data[i][5]);
          profilePicList.push(res.data[i][6]);
          userName.push(res.data[i][7]);
          postPlatform.push(res.data[i][12]);
          postId.push(res.data[i][10]);
          urlScore.push(res.data[i][11]);

          // console.log("list", res.data[i][3]);

          // 10
        }
        // const draftRes = await axios.get(
        //   `https://aware.y-f.info/twitter/draft/fetch/post/${decoded.id}`
        //   // `https://aware.y-f.info/twitter/timeline/${currentUser._id}`
        // );
        // // console.log(draftRes.data);
        // let draftRawData = draftRes.data;
        // let draftDate = [];
        // let draftName = [];
        // let draftPostId = [];
        // for (let m = 0; m < draftRes.data.length; m++) {
        //   draftDate.push(draftRes.data[m][4]);
        //   draftName.push(draftRes.data[m][6]);
        //   draftPostId.push(draftRes.data[m][7]);
        // }

        // setdraftPost(draftRawData);
        // setdraftPostsId(draftPostId);
        // setdraftDate(draftDate);
        // setdraftName(draftName);
        // console.log(typeList[0][0]);
        // console.log("length", res.data.length);
        // console.log("list", urlList);

        // setPostsUsers(postsUser);
        setPostsUrl(urlList);
        setPostsDate(dateList);
        setPostsType(typeList);
        setPostsProfilePicUrl(profilePicList);
        setPostsUserName(userName);
        setPostsPlatform(postPlatform);
        setPostsId(postId);

        setPosts(rawData);

        setPostUrlScore(urlScore);
        // console.log('tempArray', tempArray);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // fetched draft post for now only twitter
  const getDraftPosts = async () => {
    setLoading(true);
    setCurrentIndex(0);
    setPosts([["", [], []]]);
    setAnalyse(false);

    const token = localStorage.getItem("authToken");
    if (!token) {
      
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );

      // console.log(res.data);

      const draftRes = await axios.get(
        `https://aware.y-f.info/twitter/draft/fetch/post/${decoded.id}`
        // `https://aware.y-f.info/twitter/timeline/${currentUser._id}`
      );

      console.log(draftRes.data);
      let draftRawData = draftRes.data;
      let draftDate = [];
      let draftName = [];
      let draftPostId = [];
      for (let m = 0; m < draftRes.data.length; m++) {
        draftDate.push(draftRes.data[m][4]);
        draftName.push(draftRes.data[m][6]);
        draftPostId.push(draftRes.data[m][7]);
      }
      setdraftPost(draftRawData);
      console.log(draftRawData);
      setdraftPostsId(draftPostId);
      setdraftDate(draftDate);
      setdraftName(draftName);
      setLoading(false);
    }
  };

  useEffect(() => {

    const getToken = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          // console.log('Decoded: ', decoded);
          const res = await axios.get(
            `https://aware.y-f.info/api/user/${decoded.id}`
          );
          let currentUser = res.data;

          setCurrentUser(currentUser);
          // console.log('currentUser', currentUser);
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };

    getToken();
    // getTwitterPosts();
  }, [currentUser._id]);

  useEffect(() => {
    getNewUser();
  }, []);
  //
  // managing the conditional rendering after checking if any issues exist...based on the 2 arrays
  // useEffect(() => {
  // 	const hasIssues =
  // 		posts[currentIndex][1].length + posts[currentIndex][2].length > 0;
  // 	if (hasIssues) {
  // 		setAanalyseIssues(true);
  // 	} else {
  // 		setAanalyseIssues(false);
  // 	}
  // }, [currentIndex, posts]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    getDraftPosts();
  }, []);

  useEffect(() => {
    if (draftPost.length) {
      setDraftsAvailable(true);
    } else {
      setDraftsAvailable(false);
    }
  });

  useEffect(() => {
    if (showText === []) {
      setText(allIssues);
    }
  });

  return (
    <>
      {isMobile & postMobile ? <></> : <NavBar />}

      <div className="flex pb-20 sm:pb-0">
        <SideBar
          selected={selected}
          setSelected={setSelected}
          getTwitterPosts={getTwitterPosts}
          getFacebookPosts={getFacebookPosts}
          getInstagramPosts={getInstagramPosts}
          getOverallPosts={getOverallPosts}
          getDraftPosts={getDraftPosts}
          openAccountModal={openAccountModal}
        />

        <div className="flex flex-col flex-grow">
          {isLoading ? (
            <LoadingSymbol width={"20vh"} height={"20vw"} />
          ) : (
            <>
              <AddAccountModal
                isOpen={accountModal}
                setIsOpen={setAccountModal}
              />
              <FeedbackModal
                isOpen={isFeedback}
                setIsOpen={openFeedback}
                issue={feedIssue}
                setIssue={setFeedIssue}
                category={feedCategory}
                setCategory={setFeedCategory}
              />

              {isMobile & postMobile ? (
                <CreatePostMobileModal
                  postMobile={postMobile}
                  setPostMobile={setPostMobile}
                  newPost={newPost}
                  setNewPost={setNewPost}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  allPosts={posts}
                  setPosts={setPosts}
                  setAnalyse={setAnalyse}
                  analyse={analyse}
                  setCurrentIndex={setCurrentIndex}
                  setKeywords={setKeywords}
                  keywords={keywords}
                  posts={posts}
                  currentIndex={currentIndex}
                  analyseIssues={analyseIssues}
                  currentUser={currentUser}
                  isOpen={isOpen}
                  highlightColor={highlightColor}
                  setHighlightColor={setHighlightColor}
                  selected={selected}
                  editdraftId={editdraftId}
                  draftEdit={draftEdit}
                  setdraftEdit={setdraftEdit}
                  getDraftPosts={getDraftPosts}
                  showText={showText}
                  setText={setText}
                  allIssues={allIssues}
                  setAllIssues={setAllIssues}
                  getTwitterPosts={getTwitterPosts}
                />
              ) : (
                <CreatePostModal
                  currentUser={currentUser}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  newPost={newPost}
                  setNewPost={setNewPost}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  allPosts={posts}
                  setPosts={setPosts}
                  setAnalyse={setAnalyse}
                  analyse={analyse}
                  setCurrentIndex={setCurrentIndex}
                  setKeywords={setKeywords}
                  keywords={keywords}
                  posts={posts}
                  currentIndex={currentIndex}
                  analyseIssues={analyseIssues}
                  highlightColor={highlightColor}
                  setHighlightColor={setHighlightColor}
                  user={postsUserName[0]}
                  picture={postsProfilePicUrl[0]}
                  type={postsPlatform[0]}
                  selected={selected}
                  editdraftId={editdraftId}
                  draftEdit={draftEdit}
                  setdraftEdit={setdraftEdit}
                  getDraftPosts={getDraftPosts}
                  showText={showText}
                  setText={setText}
                  allIssues={allIssues}
                  setAllIssues={setAllIssues}
                  getTwitterPosts={getTwitterPosts}
                />
              )}
              {/* {
              <div className="flex flex-row gap-x-20 px-10 py-1">
                <div onClick={fB}>Back</div>
                <div className="flex-grow"></div>
                <div onClick={fN}>Next</div>
              </div>
            } */}

              {selected !== "Drafts" && postsAvailable ? (
                <>
                  <div className="">
                    <MakePost
                      name={currentUser.name}
                      isMobile={isMobile}
                      setPostMobile={setPostMobile}
                      openModal={openModal}
                      setAnalyse={setAnalyse}
                      user={postsUserName[0]}
                      picture={postsProfilePicUrl[0]}
                      type={postsPlatform[0]}
                    />
                  </div>
                  <div className="divide-y">
                    {posts.map((post, index) => {
                      return (
                        <ViewPost
                          index={index}
                          active={currentIndex === index}
                          key={index}
                          keywords={keywords}
                          textToHighlight={post[0]}
                          analyse={analyse}
                          setAnalyse={setAnalyse}
                          setCurrentIndex={setCurrentIndex}
                          setKeywords={setKeywords}
                          currentIndex={currentIndex}
                          posts={posts}
                          setPostsAvailable={setPostsAvailable}
                          // postsUsers={postsUsers}
                          highlightColor={highlightColor}
                          setHighlightColor={setHighlightColor}
                          imageUrl={postsUrl[index]}
                          date={postsDate[index]}
                          type={postsType[index]}
                          profilePicUrl={postsProfilePicUrl[index]}
                          username={postsUserName[index]}
                          postType={postsPlatform[index]}
                          postId={postsId[index]}
                          allPosts={posts}
                          setPosts={setPosts}
                          setAnalyseLoad={setAnalyseLoad}
                          analyseLoad={analyseLoad}
                          isMobile={isMobile}
                          selectAll={selectAll}
                          showText={showText}
                          setText={setText}
                          allIssues={allIssues}
                          setAllIssues={setAllIssues}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <MakeDraft
                    name={currentUser.name}
                    isMobile={isMobile}
                    setPostMobile={setPostMobile}
                    openModal={openModal}
                    setAnalyse={setAnalyse}
                    selected={selected}
                  />
                  <div className="divide-y">
                    {selected === "Drafts" && draftsAvailable ? (
                      draftPost.map((draft, draftIndex) => {
                        return (
                          <ViewDraft
                            index={draftIndex}
                            active={currentIndex === draftIndex}
                            key={draftIndex}
                            keywords={keywords}
                            textToHighlight={draft[0]}
                            analyse={analyse}
                            setAnalyse={setAnalyse}
                            setCurrentIndex={setCurrentIndex}
                            setKeywords={setKeywords}
                            currentIndex={currentIndex}
                            posts={draftPost}
                            setPosts={setPosts}
                            highlightColor={highlightColor}
                            setHighlightColor={setHighlightColor}
                            date={draftDate[draftIndex]}
                            username={draftName[draftIndex]}
                            postId={draftPostsId[draftIndex]}
                            allPosts={draftPost}
                            setDraftsAvailable={setDraftsAvailable}
                            seteditdraftId={seteditdraftId}
                            setdraftEdit={setdraftEdit}
                            openModal={openModal}
                            isMobile={isMobile}
                            setPostMobile={setPostMobile}
                            getDraftPosts={getDraftPosts}
                            setNewPost={setNewPost}
                            setAnalyseLoad={setAnalyseLoad}
                            analyseLoad={analyseLoad}
                            selectAll={selectAll}
                            showText={showText}
                            setText={setText}
                            allIssues={allIssues}
                            setAllIssues={setAllIssues}
                          />
                        );
                      })
                    ) : (
                      <div>
                        <h1 className="text-ss-black self-center text-center font-semibold mt-20">
                          No Posts Found!
                        </h1>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {isMobile ? (
          <AnalyseMobile
            analyse={analyse}
            setAnalyse={setAnalyse}
            posts={posts}
            keywords={keywords}
            setKeywords={setKeywords}
            linkDelete={deleteLink}
            currentIndex={currentIndex}
            deletePrivacy={deletePrivacy}
            analyseIssues={analyseIssues}
            setAnalyseIssues={setAnalyseIssues}
            setHighlightColor={setHighlightColor}
            setPostsAvailable={setPostsAvailable}
            deleteSentiment={deleteSentiment}
            analyseLoad={analyseLoad}
            sendFeedback={sendFeedback}
            setSelectAll={setSelectAll}
            showText={showText}
            setText={setText}
          />
        ) : (
          <Analyse
            analyse={analyse}
            posts={posts}
            keywords={keywords}
            setKeywords={setKeywords}
            linkDelete={deleteLink}
            currentIndex={currentIndex}
            deletePrivacy={deletePrivacy}
            analyseIssues={analyseIssues}
            setAnalyseIssues={setAnalyseIssues}
            setHighlightColor={setHighlightColor}
            score={postUrlScore}
            setPostsAvailable={setPostsAvailable}
            deleteSentiment={deleteSentiment}
            analyseLoad={analyseLoad}
            sendFeedback={sendFeedback}
            setSelectAll={setSelectAll}
            showText={showText}
            setText={setText}
          />
        )}
      </div>
    </>
  );
}

export default Dashboard;
