import { useState, Fragment, useEffect, useRef } from "react";

import DeleteIcon from "../assets/Icons/DeleteIcon";
import TripleDots from "../assets/Icons/TripleDots";
import Whitelist from "../assets/Icons/Whitelist";
import Report from "../assets/Icons/Report";
import Tooltip from "../common/Tooltip";
import { Popover, Transition } from '@headlessui/react'
import axios from "axios";
import jwt from "jsonwebtoken";

const MediaCard = ({ issue, score, bias, fact, setText, showText, setKeywords, keywords, deleteLink, sendFeedback}) => {

    const [selected, setSelected] = useState(false);

    useEffect(() => {
      setSelected(keywords.some((e) => e.test(issue)));
    }, [issue, keywords]);
    
    const handleClick = async () => {
        await setKeywords([RegExp(`\\b${issue}\\b`)]);
        setText([{key:issue, type:"URL"}]);
    };

    const handleReport = async () => {
        sendFeedback(issue, "URL")
        setText([])
    }
    const handleDelete = async () => {
        await deleteLink(issue);
    };
    
    const handleWhitelist = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
        window.location.href = "/login";
    } else {
        const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        var data = JSON.stringify({
        value: issue,
        });
        var url = `https://aware.y-f.info/api/user/individual/filter/${decoded.id}/Link/Add`
        var config = {
        method: "post",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
        };
        axios(config)
        .then(async (response) => {
            await handleDelete();
        })
        .catch(() => {
            alert("error");
        });
    }
    };

    let value = "";
    
    let bias_info = bias;
    let bias_color = "";

    let rate_text = fact;
    let rate_color = "";

    let url_str = "";

    /* 
    * Current Bug: Styling must be made more responsive. Labels are all over the place.
    * All Styles have been added to index.css
    */

    const ed = "</div>";
    const spanEnd = "</span>";
    const urlStart = "<div class='content_box'><div class='url-content'>";
    // enter url
    const scoreStart = "</div><div class='range-wrap'> <ul class='range-labels'> <li style='text-align: left;'>Left</li> <li> Least Biased <br> <span class='center-line'> | </span> </li> <li style='text-align: right;'>Right</li> </ul> <input type='range' class='range' min='0' max='100' value='";
    // enter score based on formula
    const biasStart = "' disabled> <output class='bubble' style='left: ";
    // add position style
    const urlScore = "</output> </div> <div>Bias Score: ";
    // enter score
    const biasEnd = "</div> <div>Direction of Bias: ";
    // enter bias
    const urlRate = "</span></div> <div>Factual Level : ";


    if (bias_info.indexOf('-') >= 0) {
        bias_info = bias_info.split('-')[0];
    }

    if (bias_info.indexOf('leftcenter') >= 0) {
        value = 20 + +(bias_info.replace("leftcenter", "") * 2);
        bias_info = 'Left Center';
        bias_color = "<span style='color: #7bb9f7;'>";
    } else if (bias_info.indexOf('left') >= 0) {
        value = 0 + +(bias_info.replace("left", "") * 2);
        bias_info = 'Left';
        bias_color = "<span style='color: #139cf1;'>";
    } else if (bias_info.indexOf('rightcenter') >= 0) {
        value = 60 + +(bias_info.replace("rightcenter", "") * 2);
        bias_info = 'Right Center';
        bias_color = "<span style='color: #e56173;'>";
    } else if (bias_info.indexOf('right') >= 0) {
        value = 80 + +(bias_info.replace("right", "") * 2);
        bias_info = 'Right';
        bias_color = "<span style='color: #e80304;'>";
    } else if (bias_info.indexOf('leastbiased') >= 0) {
        value = 40 + +(bias_info.replace("leastbiased", "") * 2);
        bias_info = 'Least Biased';
        bias_color = "<span style='color: #000000;'>";
    }

    if (rate_text == 'veryhigh') {
        rate_text = 'Very High';
        rate_color = "<span style='color: #1F7700; font-weight: bold;'>";
    } else if (rate_text == 'high') {
        rate_text = 'High';
        rate_color = "<span style='color: #318313; font-weight: bold;'>";
    } else if (rate_text == 'mostlyfactual') {
        rate_text = 'Mostly Factual';
        rate_color = "<span style='color: #4E8F37; font-weight: bold;'>";
    } else if (rate_text == 'mixed') {
        rate_text = 'Mixed';
        rate_color = "<span style='color: #F27B0C; font-weight: bold;'>";
    } else if (rate_text == 'low') {
        rate_text = 'Low';
        rate_color = "<span style='color: #B13030; font-weight: bold;'>";
    } else if (rate_text == 'verylow') {
        rate_text = 'Very Low';
        rate_color = "<span style='color: #E80000; font-weight: bold;'>";
    }

    const newValue = 8 - value * 0.15;

    url_str += urlStart + issue + scoreStart + value + biasStart + "calc("+ value +"% + ("+newValue+"px))" + "'>" +  score + urlScore + score + biasEnd + bias_color + bias_info + urlRate+ rate_color + rate_text + spanEnd + ed + ed;

    return (
        <div className={`flex flex-col gap-y-5 py-3 px-5 my-4 shadow-xl rounded-lg bg-white ${selected && "scale-75 duration-150 transition"}`} onClick={handleClick}>
            <div dangerouslySetInnerHTML={{__html: url_str}} />
            <div className="flex flex-row justify-end mr-4 gap-x-4 -mt-12">
                <Tooltip tooltipText="Dismiss">
                    <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={e => { handleDelete(); e.stopPropagation(); }}>
                        <DeleteIcon selected={selected} />
                    </button>
                </Tooltip>

                <Popover className="relative self-end">
                    <Popover.Button><TripleDots selected={selected} /></Popover.Button>

                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-75 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Popover.Panel className="absolute right-0.5 z-10 bg-gray-50 rounded-lg transition duration-150">
                            <div className="flex flex-col divide-y px-4">
                                <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={handleWhitelist}>
                                    <Whitelist />
                                    <p className="text-black">Whitelist</p>
                                </button>

                                <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={handleReport} >
                                    <Report />
                                    <p className="text-black">Report</p>
                                </button>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>
        </div>
    )
}

export default MediaCard;