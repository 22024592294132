const Report = ({selected}) =>{

    return(
        <svg width="21" height="21" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.86857 0.274902H8.13143L11.1429 3.17348V7.27658L8.13143 10.1752H3.86857L0.857143 7.27658V3.17348L3.86857 0.274902ZM2 6.82007L4.34286 9.07512H7.65714L10 6.82007V3.62999L7.65714 1.37493H4.34286L2 3.62999V6.82007Z" fill="#FFB800" />
            <path d="M6 7.97503C5.68441 7.97503 5.42857 7.72878 5.42857 7.42501C5.42857 7.12125 5.68441 6.875 6 6.875C6.31559 6.875 6.57143 7.12125 6.57143 7.42501C6.57143 7.72878 6.31559 7.97503 6 7.97503Z" fill="#FFB800" />
            <path d="M6.57143 2.4751H5.42857V6.3252H6.57143V2.4751Z" fill="#FFB800" />
        </svg>
    )
}

export default Report;