import { useEffect, useState } from "react";
import OverallPolarity from "./OverallPolarity";
import OverallPrivacy from "./OverallPrivacy";
import OverallMedia from "./OverallMedia";
/* import Card from "./Card"; */
/* import PolarityCard from "./PolarityCard"; */
import MediaCard from "./MediaCard";
import PolarityCards from "./PolarityCards";
import PrivacyCard from "./PrivacyCard";
import { round } from "../common/RoundDecimal";
import Tips from "../common/Tips";
import { Disclosure } from "@headlessui/react";

function Analyse({
  analyse,
  posts,
  setKeywords,
  currentIndex,
  deletePrivacy,
  keywords,
  analyseIssues,
  setAnalyseIssues,
  setHighlightColor,
  score,
  linkDelete,
  setPostsAvailable,
  deleteSentiment,
  analyseLoad,
  sendFeedback,
  setSelectAll,
  showText,
  setText,
}) {
  const [cardIssues, setCardIssues] = useState([]);
  const [privacyCardIssues, setPrivacyCardIssues] = useState([]);

  function checkNotNull(x) {
    if (!x) {
      setPostsAvailable(false);
      return false;
    }
    setPostsAvailable(true);
    return true;
  }

  useEffect(() => {
    //match based on word boundary to get complete words
    if (checkNotNull(posts[currentIndex])) {
      // setCardIssues(posts[currentIndex][1]);
      setCardIssues(
        posts[currentIndex][1].filter(
          (value, index, self) =>
            index !=
            self.findIndex((t) => t.key === value.key && t.type === value.type)
        )
      );
      setPrivacyCardIssues(posts[currentIndex][2]);
    } else {
      console.log("Posts are empty");
    }
  }, [currentIndex, posts]);

  useEffect(() => {
    if (privacyCardIssues.length > 0) {
      // The privacy card issues   e.g: abc@gmail.com  CCNR:1234-4321-1234-3213
      // console.log(privacyCardIssues);
      setAnalyseIssues(true);
    } else {
      if (cardIssues.length > 0) {
        // console.log(cardIssues);
        setAnalyseIssues(true);
      } else {
        if (posts[currentIndex][11]) {
          if (posts[currentIndex][11].length > 0) {
            setAnalyseIssues(true);
          } else {
            if (posts[currentIndex][12]) {
              if (posts[currentIndex][12].length > 1) {
                setAnalyseIssues(true);
              } else {
                setAnalyseIssues(false);
              }
            }
          }
        }
      }
    }
  }, [privacyCardIssues, cardIssues, setAnalyseIssues]);

/* 
  
  Function to Calculate Overall Score for Polarity Dynamically
  Can be used to replace the overall_score API call if needed.

  function getOverallScore() {
    let score = 0;

    if (posts[currentIndex][12]) {
      posts[currentIndex][12].map((pol) => {
        if (pol.sentiment) {
          score += pol["sentiment"].por * 100;
        }
      });
    }

    let average = score / posts[currentIndex][12].length;

    return round(average, 0, 2);
  } */

  return (
    <div className="h-screen analyseSidebar overflow-y-scroll sticky top-20 sm:flex flex-col pt-2 px-5 pb-24 bg-gray-50 z-10">
      {analyse && !analyseLoad ? (
        <div className="grid grid-flow-row">
          <div className="flex flex-row place-content-between gap-x-32 mt-4 text-ss-blue mx-2 font-semi-bold">
            <div className="grid grid-flow-row gap-y-1">
                <h3>Analysis of Content</h3>
            </div>
            <button
              onClick={() =>
                window.location.replace("/myaccount/#whitelist-settings")
              }
            >
              <p className="flex flex-row gap-x-1 self-center ml-auto">
                <span>
                  <img src="/imgs/settings.svg" alt="settings" />
                </span>
                Settings
              </p>
            </button>
          </div>
          <div>
            {(privacyCardIssues.length > 0 || cardIssues.length > 0) && (
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="py-2 w-full">
                      <OverallPrivacy
                        amt={privacyCardIssues.length + cardIssues.length}
                        open={open}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                      {privacyCardIssues.map((issue, index) => (
                        <PrivacyCard
                          key={index}
                          setKeywords={setKeywords}
                          keywords={keywords}
                          issue={issue[0]}
                          issueType={issue[1]}
                          deletePrivacy={deletePrivacy}
                          setHighlightColor={setHighlightColor}
                          sendFeedback={sendFeedback}
                          setSelectAll={setSelectAll}
                          setText={setText}
                        />
                      ))}

                      {cardIssues.map((issue, index) => {
                        return (
                          <PrivacyCard
                            key={index}
                            issue={issue[0]}
                            issueType={issue[1]}
                            setKeywords={setKeywords}
                            keywords={keywords}
                            currentIndex={currentIndex}
                            deletePrivacy={deletePrivacy}
                            posts={posts}
                            setHighlightColor={setHighlightColor}
                            sendFeedback={sendFeedback}
                            setText={setText}
                          />
                        );
                      })}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
          </div>

          <div>
          {analyseIssues ? (
            <>
                {posts[currentIndex][11] && (
                  <>
                    <Disclosure defaultOpen>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="py-2 w-full">
                            <OverallMedia
                              amt={posts[currentIndex][11].length}
                              open={open}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="text-gray-500">
                            {posts[currentIndex][11].map((score, index) => {
                              return (
                                <MediaCard
                                  issue={score["dataNeeded"].key}
                                  key={index}
                                  bias={score["dataNeeded"].bias}
                                  fact={score["dataNeeded"].fact}
                                  score={score["dataNeeded"].score}
                                  setText={setText}
                                  keywords={keywords}
                                  setKeywords={setKeywords}
                                  sendFeedback={sendFeedback}
                                  deleteLink={linkDelete}
                                
                                />
                              );
                            })}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </>
                )}
  
              {posts[currentIndex][12] && (
                <>
                  <Disclosure defaultOpen>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="py-2 w-full">
                          <OverallPolarity
                            overall={round((posts[currentIndex][12][0].overall_value.o_pola*100), 0, 2)}
                            open={open}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-gray-500">
                          <PolarityCards
                            issues={posts[currentIndex][12]}
                            sendFeedback={sendFeedback}
                            setText={setText}
                            showText={showText}
                            polarity={"positive"}
                            deleteSentiment={deleteSentiment}
                          />
                          <PolarityCards
                            issues={posts[currentIndex][12]}
                            sendFeedback={sendFeedback}
                            setText={setText}
                            showText={showText}
                            polarity={"negative"}
                            deleteSentiment={deleteSentiment}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </>
              )}
          </> ) : (
                <h3 className="text-center mx-auto py-10 font-medium"> No issues Found</h3>
              )}

          </div>
        </div>
      ) : (
        <div>
          <div className="grid grid-flow-col mx-2 mt-4 gap-x-20 text-ss-blue font-semi-bold">
            <h3>Analyse is on standby</h3>
            <button
              className="self-center ml-auto flex"
              onClick={() =>
                window.location.replace("/myaccount/#whitelist-settings")
              }
            >
              <p className="inline-flex gap-x-1">
                <span>
                  <img src="/imgs/settings.svg" alt="settings" />
                </span>
                Settings
              </p>
            </button>
          </div>

          {analyseLoad ? (
            <div className="w-full mt-20 flex flex-col justify-center ml-auto mr-auto">
              <img className="px-20" src="/imgs/Analyser.svg" alt="analyser" />
              <h3 className="p-5 text-ss-grey text-center">
                Analysing in Progress...
              </h3>

              <div className="self-end">
                <Tips />
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <img
                className="w-3/4 mt-20 px-1.5 grid justify-center ml-auto mr-auto"
                src="/imgs/analyse.svg"
                alt="analyse"
              />

              <div className="text-gray-600 text-lg mt-10 flex justify-center gap-x-2">
                <p className="self-center">Click</p>
                <img
                  width="100"
                  src="/imgs/analyseButtonF.svg"
                  alt="analyse false"
                />
                <p className="self-center">to activate</p>
              </div>

              <Tips />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Analyse;
