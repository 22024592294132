import React from 'react';

const AccountIcon = ({ fill }) => {
	return (
		<>
			<svg
				className={`fill-current text-${fill}`}
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M12.5 0C5.6 0 0 5.6 0 12.5C0 19.4 5.6 25 12.5 25C19.4 25 25 19.4 25 12.5C25 5.6 19.4 0 12.5 0ZM12.5 3.75C14.575 3.75 16.25 5.425 16.25 7.5C16.25 9.575 14.575 11.25 12.5 11.25C10.425 11.25 8.75 9.575 8.75 7.5C8.75 5.425 10.425 3.75 12.5 3.75ZM12.5 21.5C11.0148 21.5 9.55273 21.1325 8.2441 20.4302C6.93547 19.7279 5.82097 18.7126 5 17.475C5.0375 14.9875 10 13.625 12.5 13.625C14.9875 13.625 19.9625 14.9875 20 17.475C19.179 18.7126 18.0645 19.7279 16.7559 20.4302C15.4473 21.1325 13.9852 21.5 12.5 21.5Z" />
			</svg>
		</>
	);
};

export default AccountIcon;
