/* import { useHistory } from "react-router"; */

function SocialPillMobile({
  icon,
  text,
  setSelected,
  selected,
  getPosts,
  social,
  notifications,
  setselectedColor
}) {
 /*  const history = useHistory(); */
  return (
    <button
      id={text}
      onClick={async (e) => {
        await setSelected(e.currentTarget.id);
        try {
          getPosts();
        } catch (err) {
          console.log(err);
        }
      }}
    >
      <div
        className={`text-white p-3 w-full transition duration-150 rounded`}>
        {icon}
        {/* {social && notifications && (
          <div
            className={`rounded-full items-center justify-center p-1 h-6 w-6 text-xs ml-3 text-white ${
              selected ? `bg-red-500` : `bg-red-300`
            }`}
          >
            {notifications}
          </div>
        )} */}
      </div>
    </button>
  );
}

export default SocialPillMobile;
