const LOC = ({ selected }) => {
  return (
    <svg
    className={`cursor-pointer fill-current ${ selected ? "text-white" : "text-red-500"}`}
    width="24" height="24" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.9375 13.0426L4.54813 5.76129C3.61 5.23419 2.96875 4.22903 2.96875 3.06452C2.96875 2.25176 3.28153 1.47228 3.83828 0.897576C4.39503 0.322868 5.15014 0 5.9375 0C6.72486 0 7.47997 0.322868 8.03672 0.897576C8.59347 1.47228 8.90625 2.25176 8.90625 3.06452C8.90625 4.22903 8.265 5.23419 7.32687 5.76129L5.9375 13.0426ZM5.9375 1.22581C4.95187 1.22581 4.15625 2.0471 4.15625 3.06452C4.15625 4.08194 4.95187 4.90323 5.9375 4.90323C6.92313 4.90323 7.71875 4.08194 7.71875 3.06452C7.71875 2.0471 6.92313 1.22581 5.9375 1.22581ZM3.895 7.10968L0 5.47935V16.5116L5.795 19L13.0625 16.5116L19 18.9632V7.93097L13.0625 5.47935L7.99187 7.20774L5.9375 16.1561L3.895 7.10968Z" fill="#96501D" />
    </svg>
  );
};

export default LOC;
