const Tips = () => {

    function randomTip() {
        let Tips = [
            {
                "text": "Announcing your actual birthday online can be dangerous. You might private your account, your friends can still make a public birthday wish.",
                "title": "Unintentional Loss of Privacy"
            },
            {
                "text": "Check your name is not mentioned in full, you may wan to use Nickname.",
                "title": "Unintentional Loss of Privacy"
            },
            {
                "text": "Announcing your actual birthday online can be dangerous. You might private your account, your friends can still make a public birthday wish.",
                "title": "Unintentional Loss of Privacy"
            },
            {
                "text": "Announcing your actual birthday online can be dangerous. You might private your account, your friends can still make a public birthday wish.",
                "title": "Unintentional Loss of Privacy"
            },
            {
                "text": "Announcing your actual birthday online can be dangerous. You might private your account, your friends can still make a public birthday wish.",
                "title": "Unintentional Loss of Privacy"
            },
        ];

        return Tips[Math.floor(Math.random()*Tips.length)];
    }

    let ranTip = randomTip();
    return(
        <div className="flex flex-col px-10 py-5 mx-3 my-10 bg-white shadow-xl rounded-xl">
            <div className="flex flex-row gap-x-10">
                <img className="self-center w-8 h-8" src="/imgs/tips-icon.svg" alt="tips" />
                <h5 className="self-center text-ss-grey font-semibold">{ranTip.title}</h5>
            </div>

            <div className="text-ss-grey whitespace-pre-wrap break-words py-5">
                {ranTip.text}
            </div>
        </div>
    )
}

export default Tips;