import { useState } from "react";

import Happy from "../assets/Icons/Happy";
import Sad from "../assets/Icons/Sad";
import { round } from "../common/RoundDecimal";

var sepecialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const combineWords = (arrayOfWords) => {
  var testWord = "";
  for (let index = 0; index < arrayOfWords.length; index++) {
    const word = arrayOfWords[index];
    // console.log(word)
    if (sepecialCharacters.test(word)) {
      testWord = testWord + word; // this gives 'Happy!'
      testWord = testWord.replace("!", "");
    } else {
      testWord = testWord + " " + word;
    }
  }

  return testWord;
};

const PolarityCards = ({
  issues,
  sendFeedback,
  setText,
  showText,
  polarity,
  deleteSentiment,
}) => {
  const [tableVisible, settableVisible] = useState(false);

  async function handleClick(constIssues, emotion) {
    console.log(constIssues);
    const issues = [];
    settableVisible(!tableVisible);
    constIssues.map((issue) => {
      console.log(combineWords(issue.sentiment.sTerm));
      issues.push({
        key: combineWords(issue.sentiment.sTerm),
        type: emotion,
      });
    });

    console.log("Here ya go:", issues);
    setText(issues);
    // console.log(issues);
  }

  function Card({ emotion, constIssues }) {
    if (constIssues.length > 0) {
      return (
        <div
          className="flex-col rounded-lg bg-white shadow-xl my-5 gap-y-10"
          onClick={() => handleClick(constIssues, emotion)}
        >
          {emotion === "NEGPOL" ? (
            <div className="flex gap-x-4 flex-row bg-ss-sentiment-red p-5 rounded-t-lg">
              <Sad className="self-start" selected={true} />
              
              <h4 className="self-center text-white">
                Negative Sentiment Detected
              </h4>

            </div>
          ) : (
            <div className="flex gap-x-4 flex-row bg-ss-sentiment-green p-5 rounded-t-lg">
              <Happy className="self-start" selected={true} />

              <h4 className="self-center text-white">
                Positive Sentiment Detected
              </h4>

            </div>
          )}
          {tableVisible ? (
            <div className="flex p-3">
              <table
                className={`table-auto w-full text-center border-none ${
                  emotion === "NEGPOL"
                    ? "text-ss-sentiment-red"
                    : "text-ss-sentiment-green"
                }`}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-ss-grey">Polarity</th>
                    <th className="text-ss-grey">Subjectivity</th>
                  </tr>
                </thead>
                <tbody>
                  {constIssues.map((issue) => {
                    return (
                      <tr>
                        <td className="font-semibold">
                          {combineWords(issue.sentiment.sTerm)}
                        </td>
                        <td className="text-ss-grey">
                          {round(issue.sentiment.por, 1, 2)}
                        </td>
                        <td className="text-ss-grey">
                          {round(issue.sentiment.subj, 1, 2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="flex flex-row gap-x-5 p-5 pb-2 flex-wrap gap-y-3">
                {constIssues.map((issue) => {
                  return (
                    <div
                      className={`px-2 py-1 border-2 rounded-md ${
                        emotion === "NEGPOL"
                          ? "text-ss-sentiment-red"
                          : "text-ss-sentiment-green"
                      }`}
                    >
                      {combineWords(issue.sentiment.sTerm)}
                    </div>
                  );
                })}
              </div>
              <div className="flex text-ss-grey p-5 pt-0">
                {emotion === "NEGPOL"
                  ? "The terms used contains subject to negative tone."
                  : "The terms used contains subject to positive tone."}
              </div>
            </>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  function NegativeCard() {
    let negIssues = [];

    issues.map((issue) => {
      if (issue.sentiment) {
        if (issue.sentiment.por < 0) {
          negIssues.push(issue);
        }
      }
    });

    return <Card emotion={"NEGPOL"} constIssues={negIssues} />;
  }

  function PositiveCard() {
    let posIssues = [];

    issues.map((issue) => {
      if (issue.sentiment) {
        if (issue.sentiment.por > 0) {
          posIssues.push(issue);
        }
      }
    });
    return <Card emotion={"POSPOL"} constIssues={posIssues} />;
  }

  return <>{polarity === "negative" ? <NegativeCard /> : <PositiveCard />}</>;
};

export default PolarityCards;
