import React from "react";

const Whitelist = ({ selected }) => {
  return (
    <svg 
      className={`cursor-pointer fill-current ${ selected ? "text-white" : "text-red-500"}`}
      width="21" height="21" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2.54545L6 0L12 2.54545V6.36364C12 9.89545 9.44 13.1982 6 14C2.56 13.1982 0 9.89545 0 6.36364V2.54545Z" fill="#0DD7B2" />
      <path d="M1.38464 3.30558L6.00003 1.31421L10.6154 3.30558V6.29264C10.6154 9.05566 8.64618 11.6395 6.00003 12.2667C3.35387 11.6395 1.38464 9.05566 1.38464 6.29264V3.30558Z" fill="white" />
      <path d="M3.57703 6.90011L5.19241 8.43347L8.42318 5.14771" stroke="#0DD7B2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
};

export default Whitelist;