import ChevronDownIcon from "../assets/Icons/ChevronDownIcon";
import PrivacyIcon from "../assets/Icons/PrivacyIcon";

function OverallPrivacy({ amt, open }) {
    return (
      <div className="bg-white grid mt-3 rounded-lg py-4 px-4 shadow-xl">
        <div className="flex flex-row gap-x-6 place-content-between">
          <div className="flex flex-row gap-x-4">
            <PrivacyIcon />
            <h5 className="self-center">Privacy Issues</h5>
            <h4 className="not-italic font-semibold p-1 w-8 h-8 rounded-full bg-ss-icon-text-grey text-black self-center">{amt}</h4>
          </div>
          <div className="flex flex-row">
            <p className="text-ss-icon-text-grey self-center">Potential Leaking Term</p>
            <ChevronDownIcon open={open}/>
          </div>
        </div>
      </div>
    );
}

export default OverallPrivacy;
