import { useState, Fragment, useEffect, useRef } from "react";

import DeleteIcon from "../assets/Icons/DeleteIcon";
import PrivacyIcon from "../assets/Icons/PrivacyIcon";
import TripleDots from "../assets/Icons/TripleDots";
import Whitelist from "../assets/Icons/Whitelist";
import Report from "../assets/Icons/Report";

import Credit from "../assets/Icons/Credit";
import LOC from "../assets/Icons/LOC";
import Mail from "../assets/Icons/Mail";
import Person from "../assets/Icons/Person";

import Tooltip from "../common/Tooltip";
import CamelCase from '../common/CamelCase';
import axios from "axios";
import jwt from "jsonwebtoken";
import { Popover, Transition } from '@headlessui/react'

function PrivacyCard({
  issue,
  issueType,
  deletePrivacy,
  keywords,
  setKeywords,
  setHighlightColor,
  sendFeedback,
  setSelectAll,
  showText,
  setText,
}) {

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    console.log(issue)
    setSelected(keywords.some((e) => e.test(issue)));
  }, [issue, keywords]);

  const handleReport = async () => {
    sendFeedback(issue, issueType)
    setText([])
  }

  const handleDelete = async () => {
    await deletePrivacy(issue);
  };

  const handleWhitelist = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );

      var data = JSON.stringify({
        value: issue,
      });
      var url = (issueType === "EMAIL") ? `https://aware.y-f.info/api/user/individual/filter/${decoded.id}/Email/Add` : `https://aware.y-f.info/api/user/individual/filter/${decoded.id}/Name/Add`;
      var config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(async (response) => {
          await handleDelete();
        })
        .catch(() => {
          alert("error");
        });
    }
  };

  function returnIcon(){
    switch(issueType){
      case "PERSON":
        return(<Person selected={selected} />)

      case "CCNR":
        return(<Credit selected={selected} />)

      case "EMAIL":
        return(<Mail selected={selected} />)

      case "LOC":
        return(<LOC selected={selected} />)

      default:
        return(<PrivacyIcon selected={selected} />)
    }
  }

  function returnCardDesign(){
    switch(issueType){
      case "PERSON":
        return("card-person-highlight")

      case "CCNR":
        return("card-ccnr-highlight")

      case "EMAIL":
        return("card-email-highlight")

      case "LOC":
        return("card-loc-highlight")

      default:
        return("card-highlight")
    }
  }

  function returnTermDesign(){
    switch(issueType){
      case "PERSON":
        return("bg-ss-entity-blue")

      case "CCNR":
        return("bg-ss-hotpink")

      case "EMAIL":
        return("bg-ss-entity-yellow")

      case "LOC":
        return("bg-ss-entity-brown")

      case "URL":
        return("bg-ss-entity-grey")

      default:
        return("bg-ss-red")
    }
  }


  const handleClick = async () => {
    await setKeywords([RegExp(`\\b${issue}\\b`)]);
    setText([{key:issue, type:issueType}]);
  };
  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer mt-4 flex flex-col gap-4 shadow-xl ${
        selected ? returnCardDesign() : "bg-white"
      } bg-white rounded-lg p-4`}
    >
      <div className="flex gap-x-3">
        {returnIcon()}
        <p className="font-medium self-center">{CamelCase(issueType)} Detected</p>
        <p className={`text-xs ${selected ? "text-gray-500" : "invisible"} self-center`}>
          Term Highlighted
        </p>
      </div>
      <div className={`text-black ml-10 max-w-max p-2 rounded-lg ${returnTermDesign()} `}>
        <p className={"font-medium"}>{issue}</p>
      </div>
      <div className="flex justify-start">
        <p className="font-semibold ml-10"></p>
      </div>
      <div className="flex flex-row justify-end mr-4 gap-x-4">
        <Tooltip tooltipText="Dismiss">
            <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={e => {handleDelete();e.stopPropagation();}}>
              <DeleteIcon selected={selected}/> 
            </button>
        </Tooltip>

        <Popover className="relative self-end">
              <Popover.Button><TripleDots selected={selected}/></Popover.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-75 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Popover.Panel className="absolute right-0.5 z-10 bg-gray-50 rounded-lg transition duration-150">
                  <div className="flex flex-col divide-y px-4">
                    <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={handleWhitelist}>
                      <Whitelist /> 
                      <p className="text-black">Whitelist</p>
                    </button>

                    <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={handleReport} >
                      <Report />
                      <p className="text-black">Report</p>
                    </button>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
      </div>
    </div>
  );
}

export default PrivacyCard;
