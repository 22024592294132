const ShowHide = ({ show, setShowPassword }) => {
    if (show) {
        return (
            <svg
                className="absolute top-1/2 transform -translate-y-1 right-3 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                version="1.1"
                viewBox="-0.709 -32.081 141.732 141.732"
                xmlSpace="preserve"
                onClick={() => {
                    setShowPassword(!show);
                }}
            >
                <path fill="#3F434A" d="M89.668 38.786c0-10.773-8.731-19.512-19.51-19.512S50.646 28.01 50.646 38.786c0 10.774 8.732 19.511 19.512 19.511 10.776 0 19.51-8.736 19.51-19.511m38.684-.059c-13.315 17.599-34.426 28.972-58.193 28.972-23.77 0-44.879-11.373-58.194-28.972C25.279 21.129 46.389 9.756 70.158 9.756s44.878 11.373 58.194 28.971m11.962.033C125.666 15.478 99.725 0 70.158 0S14.648 15.478 0 38.76c14.648 23.312 40.591 38.81 70.158 38.81s55.508-15.498 70.156-38.81"></path>
            </svg>
        )
    } else {
        return (
            <svg
                className="absolute top-1/2 transform -translate-y-1 right-3 cursor-pointer"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                    setShowPassword(!show);
                }}
            >
                <path
                    d="M2.29243 6.35548C2.61993 6.1036 3.08925 6.16411 3.34223 6.49025L3.34478 6.49345C3.34816 6.49767 3.35472 6.50577 3.36448 6.51743C3.38399 6.54077 3.41624 6.57832 3.46135 6.6276C3.55162 6.72622 3.69298 6.87137 3.88633 7.04328C4.27335 7.38738 4.86585 7.83622 5.67161 8.23499C6.72929 8.75843 8.16282 9.19999 9.99966 9.19999C11.8365 9.19999 13.27 8.75843 14.3277 8.23499C15.1335 7.83622 15.726 7.38738 16.113 7.04328C16.3063 6.87137 16.4477 6.72622 16.538 6.6276C16.5831 6.57832 16.6153 6.54077 16.6349 6.51743C16.6446 6.50577 16.6512 6.49767 16.6545 6.49345L16.6568 6.49064C16.9098 6.16448 17.3794 6.1036 17.7069 6.35548C18.0352 6.608 18.0967 7.07888 17.8442 7.40722L17.8429 7.40887L17.8413 7.41093L17.8372 7.41622L17.8251 7.43146C17.8153 7.44364 17.8021 7.45983 17.7855 7.47969C17.7523 7.5194 17.7053 7.57385 17.6444 7.64039C17.5227 7.7734 17.3449 7.95515 17.1097 8.16427C16.758 8.47695 16.2764 8.8522 15.6607 9.21711L16.8819 11.6089C17.0703 11.9779 16.9239 12.4296 16.555 12.618C16.1861 12.8063 15.7343 12.6599 15.546 12.291L14.317 9.88404C13.3369 10.2842 12.1493 10.5946 10.7497 10.6779V12.95C10.7497 13.3642 10.4139 13.7 9.99966 13.7C9.58545 13.7 9.24966 13.3642 9.24966 12.95V10.6779C7.85001 10.5946 6.66248 10.2842 5.68229 9.88404L4.45335 12.291C4.265 12.6599 3.81324 12.8063 3.44433 12.618C3.07542 12.4296 2.92905 11.9779 3.11741 11.6089L4.33861 9.21711C3.72289 8.8522 3.24133 8.47695 2.88965 8.16427C2.65445 7.95515 2.47664 7.7734 2.35489 7.64039C2.29398 7.57385 2.24701 7.5194 2.2138 7.47969C2.19719 7.45983 2.18402 7.44364 2.17425 7.43146L2.16216 7.41622L2.15802 7.41093L2.15643 7.40887L2.15575 7.408C1.90323 7.07966 1.96409 6.608 2.29243 6.35548Z"
                    fill="#3F434A"
                />
            </svg>
        )
    }
}

export default ShowHide;