import { useEffect, useState, useCallback } from "react";
import OverallPolarity from "./OverallPolarity";
import OverallPrivacy from "./OverallPrivacy";
import OverallMedia from "./OverallMedia";
import MediaCard from "./MediaCard";
import PolarityCards from "./PolarityCards";
import PrivacyCard from "./PrivacyCard";
import { round } from "../common/RoundDecimal";
import { Disclosure } from "@headlessui/react";

function AnalyseMobile({
  analyse,
  setAnalyse,
  posts,
  setKeywords,
  currentIndex,
  deletePrivacy,
  keywords,
  analyseIssues,
  setAnalyseIssues,
  setHighlightColor,
  setPostsAvailable,
  deleteSentiment,
  analyseLoad,
  sendFeedback,
  linkDelete,
  showText,
  setText,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setIsVisible(false);
    setAnalyse(false);
  }, []);

  const [cardIssues, setCardIssues] = useState([]);
  const [privacyCardIssues, setPrivacyCardIssues] = useState([]);

  function checkNotNull(x) {
    if (!x) {
      setPostsAvailable(false);
      return false;
    }
    setPostsAvailable(true);
    return true;
  }

  function checkNotNull(x) {
    if (!x) {
      setPostsAvailable(false);
      return false;
    }
    setPostsAvailable(true);
    return true;
  }

  useEffect(() => {
    //match based on word boundary to get complete words
    if (checkNotNull(posts[currentIndex])) {
      setCardIssues(posts[currentIndex][1]);
      setPrivacyCardIssues(posts[currentIndex][2]);
    } else {
      console.log("Posts are empty");
    }
  }, [currentIndex, posts]);

  useEffect(() => {
    if (analyse || analyseLoad) {
      setIsVisible(true);
    }
  }, [analyse, analyseLoad]);

  useEffect(() => {
    if (privacyCardIssues.length > 0) {
      setAnalyseIssues(true);
    } else {
      if (cardIssues.length > 0) {
        setAnalyseIssues(true);
      } else {
        if (posts[currentIndex][11]) {
          if (posts[currentIndex][11].length > 0) {
            setAnalyseIssues(true);
          } else {
            if (posts[currentIndex][12]) {
              if (posts[currentIndex][12].length > 1) {
                setAnalyseIssues(true);
              } else {
                setAnalyseIssues(false);
              }
            }
          }
        }
      }
    }
  }, [privacyCardIssues, cardIssues, setAnalyseIssues]);

/*   function getOverallScore() {
    let score = 0;

    if (posts[currentIndex][12]) {
      posts[currentIndex][12].map((pol) => {
        if (pol.sentiment) {
          score += pol["sentiment"].por * 100;
        }
      });
    }

    let average = score / posts[currentIndex][12].length;

    return round(average, 0, 2);
  } */

  return (
    <div className={`${isVisible ? "z-20 fixed bottom-0 w-full h-1/2 bg-ss-white shadow-top-2xl rounded-xl p-5 overflow-auto" : "hidden"}`}>
      {analyse && !analyseLoad ? (
        <div className="grid grid-flow-row pb-24">
          <div className="flex flex-row place-content-between gap-x-32 mt-4 text-ss-blue mx-2 font-semi-bold">
            <div className="grid grid-flow-row gap-y-1">
              {analyseIssues ? (
                <>
                  <h3>Issues Found</h3>
                </>
              ) : (
                <h3>No issues Found</h3>
              )}
            </div>
            <button onClick={closeDrawer}>
              <img src="/imgs/cross.svg" alt="close" /> 
            </button>
          </div>
          <div>
            {(privacyCardIssues.length > 0 || cardIssues.length > 0) && (
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="py-2 w-full">
                      <OverallPrivacy
                        amt={privacyCardIssues.length + cardIssues.length}
                        open={open}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                      {privacyCardIssues.map((issue, index) => (
                        <PrivacyCard
                          key={index}
                          setKeywords={setKeywords}
                          keywords={keywords}
                          issue={issue[0]}
                          issueType={issue[1]}
                          deletePrivacy={deletePrivacy}
                          setHighlightColor={setHighlightColor}
                          sendFeedback={sendFeedback}
                          setText={setText}
                        />
                      ))}

                      {cardIssues.map((issue, index) => {
                        return (
                          <PrivacyCard
                            key={index}
                            issue={issue[0]}
                            issueType={issue[1]}
                            setKeywords={setKeywords}
                            keywords={keywords}
                            currentIndex={currentIndex}
                            deletePrivacy={deletePrivacy}
                            posts={posts}
                            setHighlightColor={setHighlightColor}
                            sendFeedback={sendFeedback}
                            setText={setText}
                          />
                        );
                      })}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
          </div>

          <div>
            {posts[currentIndex][11] && (
              <>
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="py-2 w-full">
                        <OverallMedia
                          amt={posts[currentIndex][11].length}
                          open={open}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="text-gray-500">
                        {posts[currentIndex][11].map((score, index) => {
                          return (
                            <MediaCard
                              issue={score["dataNeeded"].key}
                              key={index}
                              bias={score["dataNeeded"].bias}
                              fact={score["dataNeeded"].fact}
                              score={score["dataNeeded"].score}
                              setText={setText}
                              keywords={keywords}
                              setKeywords={setKeywords}
                              sendFeedback={sendFeedback}
                              deleteLink={linkDelete}
                            />
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </>
            )}

            {posts[currentIndex][12] && (
              <>
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="py-2 w-full">
                        <OverallPolarity
                          overall={round((posts[currentIndex][12][0].overall_value.o_pola*100), 0, 2)}
                          open={open}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="text-gray-500">
                        <PolarityCards
                          issues={posts[currentIndex][12]}
                          sendFeedback={sendFeedback}
                          setText={setText}
                          showText={showText}
                          polarity={"positive"}
                          deleteSentiment={deleteSentiment}
                        />
                        <PolarityCards
                          issues={posts[currentIndex][12]}
                          sendFeedback={sendFeedback}
                          setText={setText}
                          showText={showText}
                          polarity={"negative"}
                          deleteSentiment={deleteSentiment}
                        />


                        {/* Old Polarity Design - Can still be used if needed */}
                        {/* {posts[currentIndex][12].map((sent, index) => {
                              if(sent.sentiment){
                                if(sent.sentiment.por !== 0){
                                  return (
                                    <PolarityCard
                                      key={index}
                                      issue={sent["sentiment"].sTerm}
                                      polarity={sent["sentiment"].por}
                                      subjectivity={sent["sentiment"].subj}
                                      deleteEntry={deleteSentiment}
                                      currentIndex={currentIndex}
                                      keywords={keywords}
                                      setKeywords={setKeywords}
                                      setHighlightColor={setHighlightColor}
                                      sendFeedback={sendFeedback}
                                      setSelectAll={setSelectAll}
                                      setText={setText}
                                      showText={showText}
                                    />
                                  );
                                }else{
                                  <></>
                                }
                              }else{
                                <></>
                              }
                            })} */}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-row mt-4 text-ss-blue font-semi-bold self-center place-content-between">
            <h3>Analyse is on standby</h3>
            <p className="flex flex-row">
            <button onClick={closeDrawer}>
              <img src="/imgs/cross.svg" alt="close" /> 
            </button>
            </p>
          </div>
          {analyseLoad ? (
            <>
              <div className="w-full mt-20 flex flex-col justify-center">
                <img
                  className="px-20 self-center"
                  width="75%"
                  src="/imgs/Analyser.svg"
                  alt="analyser"
                />
              </div>
              <h3 className="p-5 text-ss-grey text-center">
                Analysing in Progress...
              </h3>
            </>
          ) : (
            <>
              <img
                className="w-3/4 mt-20 grid justify-center ml-auto mr-auto"
                src="/imgs/analyse.svg"
                alt="analyse"
              />

              <div className="text-gray-600 text-lg mt-10 flex justify-center gap-x-2">
                <p className="self-center">Click</p>
                <img
                  width="100"
                  src="/imgs/analyseButtonF.svg"
                  alt="analyse false"
                />
                <p className="self-center">to activate</p>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default AnalyseMobile;
