import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Button from "../common/Button.js";
import GoogleButton from "../common/GoogleButton.js";
import ShowHide from "../assets/Icons/ShowHide";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters.")
    .max(20, "Password cannot be more than 20 characters.")
    .required("Password is required."),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  terms: yup.boolean().oneOf([true], "Terms and Condition must be accepted!"),
});

function Registration() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  // console.log('Errors: ', errors);

  const onSubmit = async (userDetails) => {
    console.log(userDetails);
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.post(
        "https://aware.y-f.info/api/auth/register",
        {
          name: userDetails.name,
          email: userDetails.email,
          password: userDetails.password,
        },
        config
      );
      localStorage.setItem("authToken", data.token);
      alert("User Registered Successfully");
      window.location.href = "/login";
    } catch (error) {
      //setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <>
      <section className="bg-register w-full h-full bg-no-repeat bg-cover font-Poppins">
        <div className="grid grid-flow-col">
        <div className="flex flex-col gap-y-10">
            <div className="text-gray-700 sm:flex flex-col mt-44 mx-32 gap-y-2 hidden">
              <h1 className="font-bold text-3xl">Welcome Back,</h1>
              <h1>start planning today.</h1>
            </div>

            <iframe src="https://shareaware.y-f.info/extension/main.html" className="rounded-lg mx-32 analIframe md:flex hidden" />
          </div>
          <div className="py-20 px-4 grid justify-center text-center">
            <div className="py-10 rounded-lg grid gap-y-4 bg-white px-4 md:px-20">
              <h2 className="font-semi-bold text-2xl text-gray-600">
                Create Account
              </h2>
              <GoogleButton text="Sign Up with Google" />
              <div className="divider mt-6 text-gray-500">
                <p className="uppercase text-xs">
                  <span>or sign up with email</span>
                </p>
              </div>
              <form
                className="justify-start text-left flex flex-col gap-y-8 text-base text-gray-500 font-light"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="block">
                  <span>Full Name</span>
                  <input
                    {...register("name")}
                    type="text"
                    id="name"
                    className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                  />
                  <p>{errors.name?.message}</p>
                </div>
                <div className="block">
                  <span>Email</span>
                  <input
                    {...register("email")}
                    type="email"
                    id="email"
                    className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                  />
                  <p>{errors.email?.message}</p>
                  {/* {errors.email ? alert(errors.email.message) : null} */}
                </div>
                <div className="block">
                  <span>Password</span>
                  <div className="relative">
                    <input
                      {...register("password")}
                      type={showPassword ? `text` : `password`}
                      id="password"
                      className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                    />
                    <p>{errors.password?.message}</p>
                    {/* {console.log(errors.password?.message)} */}
                    <ShowHide show={showPassword} setShowPassword={setShowPassword}/>
                  </div>
                </div>
                <div className="block">
                  <span>Confirm Password</span>
                  <div className="relative">
                    <input
                      {...register("confirmPassword")}
                      type={showPassword ? `text` : `password`}
                      id="confirmPassword"
                      className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                    />
                    <p>
                      {errors.confirmPassword
                        ? "Password Does Not Match!"
                        : null}
                    </p>
                    {/* {console.log(
											errors.confirmPassword && 'Password Does Not Match!'
										)} */}
                    <ShowHide show={showPassword} setShowPassword={setShowPassword}/>
                  </div>
                </div>
                <div className="inline-flex items-center text-sm">
                  <input
                    {...register("terms", {
                      value: false,
                    })}
                    type="checkbox"
                    id="terms"
                    className="mr-2 text-ss-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
                  />
                  <p>
                    I accept the
                    <a className="text-ss-blue ml-1" href="#">
                      Terms and Conditions
                    </a>
                    <br />
                    <p>{errors.terms?.message}</p>
                  </p>
                </div>
                <Button type="submit" text="Create Account" />
              </form>
              <div className="text-sm mt-16 text-gray-600 font-light">
                <p>
                  Already have an account?
                  <Link to="/login" className="text-ss-blue ml-1">
                    Login
                  </Link>
                </p>
              </div>
            </div>
            <p className="text-white mt-16">
              @2022 ShareAware rights reserved
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Registration;
