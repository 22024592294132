import React from "react";

const Sad = ({ selected }) => {
  return (

    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"
      className={`cursor-pointer fill-current ${ selected ? "text-white" : "text-green-500"
        }`}>
      <path d="M11.1388 2.24513C6.21561 2.24513 2.22803 6.26392 2.22803 11.2257C2.22803 16.1874 6.21561 20.2062 11.1388 20.2062C16.062 20.2062 20.0496 16.1874 20.0496 11.2257C20.0496 6.26392 16.062 2.24513 11.1388 2.24513ZM15.0373 6.7354C15.9618 6.7354 16.7081 7.48751 16.7081 8.41924C16.7081 9.35097 15.9618 10.1031 15.0373 10.1031C14.1128 10.1031 13.3665 9.35097 13.3665 8.41924C13.3665 7.48751 14.1128 6.7354 15.0373 6.7354ZM7.24035 6.7354C8.16484 6.7354 8.91112 7.48751 8.91112 8.41924C8.91112 9.35097 8.16484 10.1031 7.24035 10.1031C6.31585 10.1031 5.56957 9.35097 5.56957 8.41924C5.56957 7.48751 6.31585 6.7354 7.24035 6.7354ZM11.1388 17.3998C8.54355 17.3998 6.32699 15.772 5.43591 13.4708H7.29604C8.07574 14.8066 9.49032 15.7159 11.1388 15.7159C12.7873 15.7159 14.213 14.8066 14.9816 13.4708H16.8417C15.9506 15.772 13.7341 17.3998 11.1388 17.3998V17.3998Z" fill="none" />
      <path d="M15.0367 10.1031C15.9595 10.1031 16.7075 9.34921 16.7075 8.41925C16.7075 7.48928 15.9595 6.7354 15.0367 6.7354C14.114 6.7354 13.366 7.48928 13.366 8.41925C13.366 9.34921 14.114 10.1031 15.0367 10.1031Z"  />
      <path d="M7.24023 10.1031C8.16297 10.1031 8.91101 9.34921 8.91101 8.41925C8.91101 7.48928 8.16297 6.7354 7.24023 6.7354C6.31749 6.7354 5.56946 7.48928 5.56946 8.41925C5.56946 9.34921 6.31749 10.1031 7.24023 10.1031Z"  />
      <path d="M11.1385 15.7159C9.48999 15.7159 8.0754 14.8066 7.29571 13.4708H5.43558C5.88311 14.6282 6.66695 15.6225 7.68451 16.3235C8.70206 17.0245 9.90592 17.3996 11.1385 17.3996C12.3711 17.3996 13.5749 17.0245 14.5925 16.3235C15.61 15.6225 16.3939 14.6282 16.8414 13.4708H14.9813C14.2127 14.8066 12.787 15.7159 11.1385 15.7159ZM11.1274 0C4.9789 0 0 5.02909 0 11.2257C0 17.4222 4.9789 22.4513 11.1274 22.4513C17.2869 22.4513 22.277 17.4222 22.277 11.2257C22.277 5.02909 17.2869 0 11.1274 0ZM11.1385 20.2062C6.21528 20.2062 2.2277 16.1874 2.2277 11.2257C2.2277 6.26392 6.21528 2.24513 11.1385 2.24513C16.0617 2.24513 20.0493 6.26392 20.0493 11.2257C20.0493 16.1874 16.0617 20.2062 11.1385 20.2062Z"  />
    </svg>

  );
};

export default Sad;
