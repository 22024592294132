import DeleteIcon from "../assets/Icons/DeleteIcon";
import Tooltip from "../common/Tooltip";
import ChevronDownIcon from "../assets/Icons/ChevronDownIcon";
import Happy from "../assets/Icons/Happy";
import Sad from "../assets/Icons/Sad";

function OverallPolarity({ overall, open }) {
  if(overall !== 0 && overall){
    return (
      <div className="flex flex-row w-full place-content-between bg-white mt-3 rounded-xl p-4 shadow-xl">
        <div className={`flex flex-row gap-x-4 ${overall < 0 ? "text-ss-sentiment-red":"text-ss-sentiment-green"}`}>
            {overall >= 0 ?
              <Happy className="self-center" />
            :
              <Sad  className="self-center" />
            }
          
            <h4 className="self-center">Overall {overall < 0 ? <>Negative</> : <>Positive</>} Polarity </h4>
            <h5 className="self-center">Score: <em className="font-semibold not-italic text-lg">{overall}</em></h5>
        </div>
        <ChevronDownIcon open={open}/>
      </div>
    );
  }
  else{
    return <></>
  }
}

export default OverallPolarity;
