import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import queryString from 'query-string';
import Button from "../common/Button.js";
import ShowHide from "../assets/Icons/ShowHide";
import GoogleButton from "../common/GoogleButton.js";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required."),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters.")
    .max(20, "Password cannot be more than 20 characters.")
    .required("Password is required."),
});

const Login = ({ history }) => {
  const location = useLocation();
  console.log("Link", location);

  useEffect(()=>{
    const token = queryString.parse(location.search);
    if(token.token){
      localStorage.setItem("authToken", token.token);
      console.log("Logging into Google")
      setIsLoggedIn(true);
    }
  },[]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoggedIn = () => {
      let check = !!localStorage.getItem("authToken");
      setIsLoggedIn(check);
      if (isLoggedIn) {
        window.location.href = "/dashboard";
      } else {
        console.log("Invalid Credentials");
      }
    };
    checkLoggedIn();
  }, [isLoggedIn]);

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (userDetails) => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.post(
        "https://aware.y-f.info/api/auth/login",
        {
          email: userDetails.email,
          password: userDetails.password,
        },
        config
      );
      // console.log(userDetails);
      localStorage.setItem("authToken", data.token);
      console.log("DATA", data);
      window.location.href = "/";
    } catch (error) {
      // setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <>
      <section className="bg-login w-full h-full bg-no-repeat bg-cover font-Poppins">
        <div className="grid grid-flow-col">
          <div className="flex flex-col gap-y-10">
            <div className="text-white sm:flex flex-col mt-44 mx-32 gap-y-2 hidden">
              <h1 className="font-bold text-3xl">Welcome Back,</h1>
              <h1>start planning today.</h1>
            </div>

            <iframe src="https://shareaware.y-f.info/extension/main.html" className="rounded-lg mx-32 analIframe md:flex hidden" />
          </div>
          <div className="py-32 grid justify-center text-center">
            <div className="pt-10 pb-36 rounded-xl grid gap-y-4 bg-white px-4 md:px-20">
              <div className="flex justify-center items-center font-bold">
                <img className="h-20 w-20" src="logo.png" alt="logo" />
                <h1 className="text-ss-blue ml-4 lg:text-2xl">
                  ShareAware
                </h1>
              </div>
              <h2 className="font-semi-bold text-2xl text-gray-600 mt-6">
                Login
              </h2>
              <GoogleButton text="Login with Google" />
              <div className="divider mt-6 text-gray-500">
                <p className="uppercase text-xs">
                  <span>or login with email</span>
                </p>
              </div>
              <form
                className="justify-start text-left flex flex-col gap-y-8 text-base text-gray-500 font-light"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="block">
                  <span>Email</span>
                  <input
                    {...register("email")}
                    type="email"
                    id="email"
                    className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                  />
                  <p>{errors.email?.message}</p>
                </div>
                <div className="block">
                  <span>Password</span>
                  <div className="relative">
                    <input
                      {...register("password")}
                      type={showPassword ? `text` : `password`}
                      id="password"
                      className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                    />
                    <p>{errors.password?.message}</p>
                    <ShowHide show={showPassword} setShowPassword={setShowPassword}/>
                  </div>
                </div>

                <div className="inline-flex items-center text-sm">
                  <input
                    {...register("terms")}
                    type="checkbox"
                    id="terms"
                    className="mr-2 text-ss-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
                  />
                  <div className=" inline-flex justify-between w-full">
                    <p>Remember Me</p>
                    <a className="text-ss-blue" href="#">
                      Forgot Password?
                    </a>
                  </div>
                </div>
                {error && (
                  <span
                    style={{
                      textAlign: "center",
                      color: "red",
                      border: "1px solid red",
                    }}
                  >
                    {error}
                  </span>
                )}
                <Button type="submit" text="Log In" />
              </form>
              <div className="text-sm mt-4 text-gray-600 font-light">
                <p>
                  Don't have an account?
                  <Link to="/registration" className="text-ss-blue ml-1">
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
            <p className="text-white mt-16">
              @2022 ShareAware rights reserved
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
